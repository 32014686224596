import type React from 'react'
import '../WithdrawPage.scss'
import { useNavigate } from 'react-router-dom'
import { useStore } from 'src/models/Store'
import { colors } from 'src/assets/scss/themes'
import InfoIcon from 'src/assets/images/svg/InfoIcon'
import Select from 'src/components/Select/Select'
import Button, { EButtonType } from 'src/ui/Button'
import { type WithdrawFormInputs, type formStateType } from '../WithdrawPage'
import { type Control, Controller, type UseFormSetValue } from 'react-hook-form'
import { observer } from 'mobx-react'
import { useState } from 'react'
import Preloader from 'src/ui/Preloader'
import NoTFASideBarComponent from 'src/components/EnableTFASidebarComponent/EnableTFASidebarComponent'
import { type OptionProps, components } from 'react-select'
import WithdrawAddressItem from '../WithdrawAddressItem/WithdrawAddressItem'
import { type BalanceItem } from 'src/models/BalancesStore'
import { type TWithdrawAddress } from 'src/models/WithdrawAddressesStore'

interface WithdrawSelectWalletProps {
  setFormStep: formStateType
  formControl: Control<WithdrawFormInputs, any>
  setCurrentWallet: UseFormSetValue<WithdrawFormInputs>
  balanceItem: BalanceItem
}

const WithdrawSelectWallet: React.FC<WithdrawSelectWalletProps> = observer(
  ({ setFormStep, formControl, setCurrentWallet, balanceItem }) => {
    const navigate = useNavigate()
    const { initialStore, withdrawAddressesStore, userStore } = useStore()
    const [selectedNetwork, setSelectedNetwork] = useState<{
      label: string
      value: number
    } | null>(null)
    const [withdrawAddresses, setWithdrawAddresses] = useState<
      TWithdrawAddress[] | null
    >(null)
    const availableNetworks = balanceItem?.operations_availability.filter(
      (item) => item.type === 'settlement_withdraw'
    )

    const filteredNetworks = initialStore.networks.items.filter((network) =>
      availableNetworks?.some(
        (availableNetwork) => availableNetwork.network_id === network.id
      )
    )

    const selectOptions = filteredNetworks.map((network) => {
      return { label: network.name, value: network.id }
    })

    // React select custom component for icons near options in the dropdown
    const Option = (
      props: OptionProps<{ label: string; value: number }, false>
    ): JSX.Element => {
      return (
        <components.Option {...props}>
          <img
            src={
              process.env.PUBLIC_URL +
              `/assets/images/networks/${props.data.label}.svg`
            }
            // TODO: Leave alt empty for now, change when most images and networks are aligned on the backend
            alt={''}
          />
          {props.children}
        </components.Option>
      )
    }
    if (!userStore.user.tfa_enabled) return <NoTFASideBarComponent />

    return (
      <>
        <h3>Select wallet for withdraw</h3>
        <div className="WithdrawPage-Warning">
          <InfoIcon fillbackground={colors.error} fillinside={colors.white} />
          <span>
            Please check carefully your network selection. If the top up is
            submitted within the wrong network, the funds will be lost.
          </span>
        </div>
        <Controller
          control={formControl}
          name="network"
          render={({ field: { onChange, value } }) => (
            <Select
              options={selectOptions}
              defaultValue={value}
              transparent
              placeholder="Select network"
              components={{ Option }}
              onChange={async (item) => {
                onChange(item)
                if (item !== null) {
                  setSelectedNetwork(item)
                  const response =
                    await withdrawAddressesStore.getWithdrawAddresses({
                      network_id: item.value,
                      page_size: 1000
                    })
                  if (response !== undefined) {
                    setWithdrawAddresses(response)
                  }
                }
              }}
            />
          )}
        />
        <div className="WithdrawPage-ButtonGroup">
          <Button
            title="Add new address"
            onClick={() => {
              navigate('add')
            }}
          />
          <Button
            title="Manage addresses"
            buttonType={EButtonType.SECONDARY}
            disabled={
              withdrawAddressesStore.withdrawAddresses.length <= 0 ||
              selectedNetwork == null
            }
            onClick={() => {
              if (selectedNetwork != null) {
                navigate('manage/' + selectedNetwork.value.toString())
              }
            }}
          />
        </div>
        <div className="WithdrawPage-AddressList">
          {withdrawAddressesStore.isLoading && <Preloader />}
          {selectedNetwork === null && (
            <div className="WithdrawPage-NoAddress">
              Select network to see addresses.
            </div>
          )}
          {withdrawAddresses?.map((withdrawAddress) => (
            <WithdrawAddressItem
              key={withdrawAddress.id}
              // @ts-expect-error todo fix this
              networkName={selectedNetwork?.label}
              withdrawAddress={withdrawAddress}
              onClick={() => {
                setCurrentWallet('selectedWallet', withdrawAddress)
                setFormStep('enterAmount')
              }}
            />
          ))}
          {withdrawAddressesStore.withdrawAddresses.length < 1 &&
            selectedNetwork != null && (
              <div className="WithdrawPage-NoAddress">
                No addresses found, please add a new one.
              </div>
            )}
        </div>
      </>
    )
  }
)
export default WithdrawSelectWallet
