import { createColumnHelper } from '@tanstack/react-table'
import { observer } from 'mobx-react'
import { useEffect, useMemo } from 'react'
import { Table } from 'src/components/Table/Table'
import { useStore } from 'src/models/Store'
import Preloader from 'src/ui/Preloader'
import TextComponent from 'src/ui/TextComponent/TextComponent'
import { formatDate } from 'src/utils/helpers/formatDate'
import { type RiskCheckAddress } from 'src/models/RiskCheckStore/RiskCheckStore.types'
import { PageActionsWrapper } from 'src/ui/Wrappers/PageActionsWrapper'
import Button from 'src/ui/Button'
import { EyeOpenIcon } from 'src/assets/svg'
import { Outlet, useNavigate } from 'react-router-dom'
import PaginationComponent from 'src/ui/Pagination/Pagination'
import styles from './RiskCheckPage.module.scss'
import classNames from 'classnames'

const RiskCheckPage = observer(() => {
  const { riskCheckStore, merchantStore } = useStore()
  const { riskCheckHistory } = riskCheckStore
  const navigate = useNavigate()

  useEffect(() => {
    riskCheckStore.subscribe()
    return () => {
      riskCheckStore.unsubscribe()
    }
  }, [merchantStore.currentMerchant])

  const columnHelper = createColumnHelper<RiskCheckAddress>()
  const columns = useMemo(
    () => [
      columnHelper.accessor('address', {
        header: () => (
          <TextComponent body4 bold>
            Address
          </TextComponent>
        ),
        cell: (cell) => <TextComponent body2>{cell.getValue()}</TextComponent>,
        enableSorting: false
      }),
      columnHelper.accessor('datetime', {
        header: () => (
          <TextComponent body4 bold>
            Date checked
          </TextComponent>
        ),
        cell: (cell) => (
          <TextComponent body2>
            {formatDate(new Date(cell.getValue()))}
          </TextComponent>
        ),
        enableSorting: false
      }),

      columnHelper.accessor('risk_percent', {
        header: () => (
          <TextComponent body4 bold>
            Risk level
          </TextComponent>
        ),
        cell: (cell) => {
          const riskLevel = cell.row.original.risk_level
          return (
            <div
              className={classNames(styles.RiskPercent, {
                [styles.Low]: riskLevel === 'Low',
                [styles.High]: riskLevel !== 'Low'
              })}
            >
              <TextComponent body2>{cell.getValue()}%</TextComponent>
            </div>
          )
        },
        enableSorting: false
      })
    ],
    []
  )

  if (riskCheckStore.riskCheckHistory.isLoading) {
    return <Preloader />
  }

  return (
    <div className="RiskCheckPage">
      <PageActionsWrapper>
        <Button
          title="Check Address"
          icon={<EyeOpenIcon fill="var(--color-primary900)" />}
          onClick={() => {
            navigate('check-address')
          }}
        />
      </PageActionsWrapper>
      {riskCheckHistory.items.length > 0 ? (
        <>
          <Table
            data={riskCheckStore.riskCheckHistory.items}
            columns={columns}
          />
          <PaginationComponent pagination={riskCheckStore.pagination} />
        </>
      ) : (
        <TextComponent centered>There are no risk checks</TextComponent>
      )}
      <Outlet />
    </div>
  )
})
export default RiskCheckPage
