import Header from '../../components/Header/Header'
import { Row } from 'react-bootstrap'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'
import { type ReactElement, useEffect, useRef } from 'react'
import type React from 'react'
import { useStore } from '../../models/Store'
import Preloader from '../Preloader'
import { CSSTransition } from 'react-transition-group'
import './Layout.scss'
// import Intercom from '@intercom/messenger-js-sdk'

/**
 *
 * This modal is used with react Router
 *
 * Use this when modal has to be displayed through a route
 */
const SideBarModal = observer(
  ({ children }: { children?: React.ReactNode }): JSX.Element => {
    // const [isModalOpen, setIsModalOpen] = useState(true)
    const { utilityStore } = useStore()
    const navigate = useNavigate()
    const nodeRef = useRef(null)

    useEffect(() => {
      utilityStore.openSideBarModal()
    }, [])

    const handleClose = (): void => {
      navigate('..')
    }

    const handleContentClick = (e: React.MouseEvent<HTMLDivElement>): void => {
      e.stopPropagation()
    }
    return (
      <CSSTransition
        classNames="slide-left"
        nodeRef={nodeRef}
        in={utilityStore.sideBarModalOpen}
        timeout={300}
        appear
        mountOnEnter
        unmountOnExit
        onExited={() => {
          handleClose()
        }}
      >
        <div
          ref={nodeRef}
          className={'SideBarModal'}
          onClick={(e) => {
            utilityStore.closeSideBarModal()
          }}
        >
          <div className={'SideBarModal-Overlay'} />
          <div
            className={'SideBarModal-Content'}
            onClick={(e) => {
              handleContentClick(e)
            }}
          >
            {(<Outlet />) as ReactElement | null | undefined}
            {children}
          </div>
        </div>
      </CSSTransition>
    )
  }
)

const ProtectedPage = observer(() => {
  const { authStore, initialStore, userStore } = useStore()
  const location = useLocation()
  // Intercom({
  //   app_id: 's2bp2ckj',
  //   user_id: userStore.user.id.toString(),
  //   name: userStore.user.email,
  //   email: userStore.user.email,
  //   created_at: Date.now(),
  //   user_hash: userStore.user.user_hash ?? ''
  // })
  if (!authStore.isFetched)
    return (
      <Row
        style={{ height: '100vh' }}
        className={'justify-content-center align-content-center'}
      >
        <Preloader />
      </Row>
    )
  if (!authStore.isAuth && authStore.isFetched) {
    return <Navigate to="/login" state={{ from: location }} />
  }
  if (!initialStore.isFetched || initialStore.isLoading) {
    return (
      <Row
        style={{ height: '100vh' }}
        className={'justify-content-center align-content-center'}
      >
        <Preloader />
      </Row>
    )
  }
  return (
    <main className="Layout">
      <Header />
      <Row className={`outlet-row ${authStore.isFetched.toString()}`}>
        <Outlet />
      </Row>
    </main>
  )
})

export { ProtectedPage, SideBarModal }
