import TextComponent from 'src/ui/TextComponent/TextComponent'
import styles from './MerchantsDropdown.module.scss'
import { DropdownIcon } from 'src/assets/svg'
import { useDetectClickOutside } from 'react-detect-click-outside'
import { useState } from 'react'
import classNames from 'classnames'
import { useStore } from 'src/models/Store'
import { observer } from 'mobx-react'
import MerchantDropdownItem from './MerchantDropdownItem'

const MerchantsDropdown = observer(() => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const ref = useDetectClickOutside({
    onTriggered: () => {
      setDropdownOpen(false)
    }
  })
  const { merchantStore } = useStore()
  const dropdownClassnames = classNames(styles.Dropdown, {
    [styles.Visible]: dropdownOpen
  })

  return (
    <div className={styles.MerchantsDropdown} ref={ref}>
      <div
        className={styles.Content}
        onClick={() => {
          setDropdownOpen((prevState) => !prevState)
        }}
      >
        <TextComponent>{merchantStore.currentMerchant?.name}</TextComponent>
        <DropdownIcon />
      </div>
      <div className={dropdownClassnames}>
        {merchantStore.merchants.map((merchant) => (
          <MerchantDropdownItem
            merchant={merchant}
            onClick={() => {
              void merchantStore.setCurrentMerchant(merchant)
              setDropdownOpen(false)
            }}
            key={merchant.id}
          />
        ))}
      </div>
    </div>
  )
})
export default MerchantsDropdown
