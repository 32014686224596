import { autorun, makeAutoObservable, runInAction } from 'mobx'
import type Store from './Store'
import { type BasePaginationRequest } from './FetchModel'
import PaginationStore from './PaginationStore'
import FiltersStore from './FiltersStore'
type TFetchWidgetData = {
  success: boolean
  data: {
    limits: { min: number; max: number }
  }
}

export type FiatBankAccountsDetails = {
  id: number
  account_name: string
  bank_name: string
  iban: string
  bic: string
  reference_code?: string
}

type DepositCredentials = {
  account_name: string
  bank_name: string
  iban: string
  bic: string
  reference_code: string | null
}

class FiatStore {
  constructor(private readonly rootStore: Store) {
    makeAutoObservable(this)
    this.pagination = new PaginationStore()
    this.filters = new FiltersStore<
      DepositCredentials & BasePaginationRequest
    >()
  }

  depositKey: string = ''
  withdrawKey: string = ''
  withdrawError: boolean = false
  withdrawReady: boolean = false
  withdrawLimits: { min: number; max: number } = { min: 0, max: 0 }
  savedWithdrawAccounts: FiatBankAccountsDetails[] = []
  currentDepositCredentials: DepositCredentials | null = null

  pageSize = 10
  pagination
  filters
  listener: () => void = () => {}

  subscribe(): void {
    this.listener = autorun(() => {
      if (this.rootStore.merchantStore.currentMerchant != null) {
        void this.FetchSavedWithdrawAccounts()
      }
    })
  }

  unsubscribe(): void {
    this.listener()
    this.pagination.reset()
  }

  FetchWithdraw(): void {
    if (this.withdrawReady) return
    Promise.all([this.FetchWithdrawKey(), this.FetchSavedWithdrawAccounts()])
      .then(async () => {
        await this.FetchWidgetData()
      })
      .catch((e) => {
        console.log(e)
        runInAction(() => {
          this.withdrawError = true
        })
      })
      .finally(() => {
        runInAction(() => {
          this.withdrawReady = true
        })
      })
  }

  async Payout(fields: {
    id: number
    bank_name: string
    bic: string
    reference_code?: string | undefined
    owner_name: string
    customer_purse: string
    amount: string
  }): Promise<{ success: boolean; data: Record<string, unknown> } | undefined> {
    return await this.rootStore.api.post(
      `/cashier/payout/?saved=false&key=${this.withdrawKey}`,
      {
        ...fields,
        group: 1020
      }
    )
  }

  async FetchWidgetData(): Promise<void> {
    const res = await this.rootStore.api.get<Promise<TFetchWidgetData>>(
      `/cashier/get_payout_widget_data/1020/?saved=false&key=${this.withdrawKey}`
    )

    if (res?.data.limits !== undefined) {
      runInAction(() => {
        this.withdrawLimits = res.data.limits
      })
    }
  }

  async FetchSavedWithdrawAccounts(): Promise<void> {
    const response = await this.rootStore.api.get<
      {
        items: Array<FiatBankAccountsDetails & { date_created: string }>
      } & BasePaginationRequest
    >('/jsapi/fiat/credentials', this.filters.activeFilters)

    if (response !== undefined) {
      this.savedWithdrawAccounts = response.items
    }
  }

  async AddWithdrawAccount(data: {
    tfa_code: string & FiatBankAccountsDetails
  }): Promise<
    (FiatBankAccountsDetails & { date_created: string }) | undefined
  > {
    const res = await this.rootStore.api.post<
      FiatBankAccountsDetails & { date_created: string }
    >('/jsapi/fiat/credentials', data, { content: 'Account created!' })

    if (res !== undefined) {
      runInAction(() => {
        this.savedWithdrawAccounts = [...this.savedWithdrawAccounts, res]
      })
    }
    return res
  }

  async FetchKey(
    settlement: 'settlement' | 'deposit'
  ): Promise<string | undefined> {
    const response = await this.rootStore.api.get<{
      key: string
    }>(`jsapi/fiat/get-cashier-key?action=${settlement}&currency=EUR`)
    if (response !== undefined) {
      return response.key
    }
  }

  async FetchDepositKey(): Promise<any> {
    return await this.FetchKey('deposit')
  }

  async FetchWithdrawKey(): Promise<void> {
    const response = await this.FetchKey('settlement')
    if (response !== undefined) {
      runInAction(() => {
        this.withdrawKey = response
      })
    }
  }

  async EditFiatBankAccount(data: {
    id: string | number
    account_name: string
    tfa_code: string
  }): Promise<FiatBankAccountsDetails | undefined> {
    const { id, account_name, tfa_code } = data

    const response = await this.rootStore.api.update<FiatBankAccountsDetails>(
      `/jsapi/fiat/credentials/${id}`,
      { account_name, tfa_code },
      { content: 'Bank account details changed!' }
    )
    if (response !== undefined) {
      const changedElementIndex = this.savedWithdrawAccounts.findIndex(
        (account) => account.id === response.id
      )
      const updatedAccounts = this.savedWithdrawAccounts.toSpliced(
        changedElementIndex,
        1,
        {
          ...response
        }
      )
      runInAction(() => {
        this.savedWithdrawAccounts = updatedAccounts
      })
      return response
    }
  }

  async DeleteFiatBankAccount(data: {
    id: string | number
    tfa_code: string
  }): Promise<FiatBankAccountsDetails | undefined> {
    const { id, tfa_code } = data

    const response = await this.rootStore.api.delete<FiatBankAccountsDetails>(
      `/jsapi/fiat/credentials/${id}`,
      { tfa_code },
      { content: 'Bank account deleted!' }
    )
    if (response !== undefined) {
      runInAction(() => {
        void this.FetchSavedWithdrawAccounts()
      })
      return response
    }
  }

  async getDepositCredentials(currency: string) {
    const response = await this.rootStore.api.get<DepositCredentials>(
      '/jsapi/fiat/credentials/deposit',
      { currency }
    )
    if (response !== undefined) {
      runInAction(() => {
        this.currentDepositCredentials = response
      })
      return response
    }
    this.currentDepositCredentials = null
    return undefined
  }
}

export default FiatStore
