import { runInAction, makeAutoObservable, reaction } from 'mobx'
import type Store from './Store'

class WebsocketStore {
  constructor(private readonly rootStore: Store) {
    makeAutoObservable<WebsocketStore, 'rootStore'>(this, {
      rootStore: false
    })
    reaction(
      () => this.rootStore.merchantStore.currentMerchant,
      () => {
        if (this.rootStore.authStore.isAuth) {
          this.isConnected = false
          this.initialize()
        } else {
          this.client?.close()
          runInAction(() => {
            this.isConnected = false
          })
        }
      }
    )
  }

  client: WebSocket | null = null
  isConnected = false

  initialize() {
    this.client = new WebSocket('/ws-api')
    if (this.client != null) {
      this.client.onopen = () => {
        runInAction(() => {
          this.isConnected = true
        })
      }
    }
  }
}

export default WebsocketStore
