/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import type Store from './Store'
import { autorun, makeAutoObservable, runInAction, when } from 'mobx'
import FiltersStore from './FiltersStore'
import { type TCoinItem } from './InitialDataStore'

export type BalanceItem = {
  id: number
  is_fiat: boolean
  currency: string
  balance: string
  operations_availability: BalanceItemAvailableOperation[]
  memo?: string
}

export type BalanceItemAvailableOperation = {
  type:
    | 'top_up_deposit'
    | 'settlement_withdraw'
    | 'customer_withdraw_with_exchange'
    | 'exchange_withdraw'
    | 'settlement_withdraw'
    | 'customer_withdraw'
    | 'customer_deposit_with_exchange'
    | 'customer_deposit'
  network_id: number
  available: boolean
}
export type TBalanceGetParams = {
  is_virtual?: boolean
}
export type TBalanceWithdrawParams = {
  network_id: number
  coin_id: number
  from_balance_id: number
  amount: number
  destination: string
  tfa_code: string
}

export type PaymentAttribute = {
  is_memo_based: boolean
  address: string
  memo: null | string
}
type BalancesPageFilters = { balance_type?: 'fiat' | 'crypto' }

class BalancesStore {
  constructor(private readonly rootStore: Store) {
    this.rootStore = rootStore
    makeAutoObservable<BalancesStore, 'rootStore'>(this, { rootStore: false })
    this.filters = new FiltersStore<BalancesPageFilters>()

    when(
      () =>
        this.rootStore.authStore.isAuth &&
        this.rootStore.merchantStore.currentMerchant != null,
      () => {
        autorun(() => {
          if (this.rootStore.merchantStore.currentMerchant) {
            void this.getBalances(this.filters.activeFilters)
          }
        })
      }
    )
  }

  public items: BalanceItem[] = []
  public filters
  public paymentAttributes: Record<string, PaymentAttribute> = {}

  async getBalances(params: BalancesPageFilters) {
    const response = await this.rootStore.api.get<BalanceItem[]>(
      '/jsapi/balances',
      params
    )

    if (response !== undefined) {
      this.setBalancesItems(response)
    }
  }

  setBalancesItems(items: BalanceItem[]): void {
    this.items = items.sort(
      (a, b) => parseFloat(b.balance) - parseFloat(a.balance)
    )
  }

  getPaymentAttributes = async (networkId: string | number) => {
    const response = await this.rootStore.api.get<
      Record<string, PaymentAttribute>
    >('/jsapi/payment-attributes', { network_id: networkId })
    if (response !== undefined) {
      runInAction(() => {
        this.paymentAttributes = response
      })
    }
    return response
  }

  async withdraw(params: {
    currency: string
    network_id: number
    amount: number
    destination: number
  }): Promise<
    | {
        need_confirm: boolean
        operation_id: number
        transaction_id: number
      }
    | undefined
  > {
    const payload = {
      amount: +params.amount,
      currency: params.currency,
      network_id: params.network_id,
      destination: params.destination
    }
    return await this.rootStore.api.post<{
      need_confirm: boolean
      operation_id: number
      transaction_id: number
    }>('/jsapi/settlement', { ...payload })
  }

  findIdByName(
    search: 'coins' | 'networks',
    name: string
  ): TCoinItem['id'] | undefined {
    const s = this.rootStore.initialStore[search].items
    return s.find((v) => {
      return v.name === name
    })?.id
  }

  // TODO: Probably need to change findIdByName to return the whole thing later on
  findIdByNameAndReturn(
    search: 'coins' | 'networks',
    name: string
  ): TCoinItem | undefined {
    const s = this.rootStore.initialStore[search].items
    return s.find((v) => {
      return v.name === name
    })
  }

  /**
   * Tries to find a balance item with given key value pair
   *
   * Example: findBalanceItem('coin', 'ETH')
   */
  findBalanceItem(
    itemKeyName: keyof BalanceItem,
    value: string | number | boolean
  ): BalanceItem | undefined {
    return this.items.find((item) => item[itemKeyName] === value)
  }

  getCurrencyNetworkId = (currencyId: BalanceItem['id']) => {
    return this.items.find((item) => item.id === currencyId)
      ?.operations_availability[0].network_id as number
  }

  getNetworkPaymentAttribute = (networkId: string) => {
    if (Object.keys(this.paymentAttributes).length === 0) {
      return undefined
    }
    return this.paymentAttributes[networkId]
  }
}

export default BalancesStore
