import { makeAutoObservable, reaction } from 'mobx'
import type Store from './Store'

import axios, { type RawAxiosRequestHeaders } from 'axios'
import { EAlertTypes } from './AlertsStore'

export const axiosInstance = axios.create({
  baseURL: '/',
  data: {}
})

export type BasePaginationRequest = {
  page: number
  page_size: number
  total: number
}

class ResponseHandler {
  authenticated: boolean = false

  constructor(private readonly rootStore: Store) {
    this.rootStore = rootStore
    makeAutoObservable<ResponseHandler, 'rootStore'>(this, { rootStore: false })
    reaction(
      () => this.authenticated,
      (authenticated) => {
        this.rootStore.authStore.setAuth(authenticated)
      }
    )
  }

  checkAuth(sessionKey: string): void {
    this.authenticated = !(sessionKey === '')
  }

  checkResponseStatus(response: any): void {
    console.log('Response status', response)
    if (response.status >= 400) {
      this.rootStore.alertsStore.addAlert({
        id: (response.status as number) + Date.now(),
        title: 'Error',
        content: response.message,
        timeout: 3000,
        type: EAlertTypes.ERROR
      })
      if (response.status === 401) {
        this.rootStore.authStore.setAuth(false)
        return response
      }
    }
    return response.data
  }
}

export class FetchModel {
  responseHandler: ResponseHandler

  constructor(private readonly rootStore: Store) {
    this.responseHandler = new ResponseHandler(rootStore)
    makeAutoObservable<FetchModel, 'rootStore'>(this, { rootStore: false })
  }

  get headers(): RawAxiosRequestHeaders {
    return {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  }

  errorHandler = (e: any): void => {
    if (e.response.status === 401) {
      // Custom handle for login page, since it answers with 401 custom message
      if (e.response.data.message !== 'Unauthorized') {
        this.rootStore.alertsStore.addAlert({
          id: (e.response.status as number) + Date.now(),
          title: 'Error',
          content: e.response.data.message,
          timeout: 3000,
          type: EAlertTypes.ERROR
        })
        return
      }
      this.rootStore.authStore.setAuth(false)
      // this.rootStore.alertsStore.addAlert({
      //   id: (e.response.status as number) + Date.now(),
      //   title: 'Session expired',
      //   content: 'Your session has expired, please log in',
      //   date: Date.now().toString(),
      //   timeout: 3000,
      //   type: EAlertTypes.WARNING
      // })
      return
    }
    // Validation error handling
    if (e.response.status === 422) {
      this.rootStore.alertsStore.addAlert({
        id: (e.response.status as number) + Date.now(),
        title: 'Error',
        content: e.response.data[0].msg,
        timeout: 3000,
        type: EAlertTypes.ERROR
      })
      return
    }
    if (e.response.status >= 400 && e.response.status < 500) {
      this.rootStore.alertsStore.addAlert({
        id: (e.response.status as number) + Date.now(),
        title: 'Error',
        content: e.response.data.message,
        timeout: 3000,
        type: EAlertTypes.ERROR
      })
      // eslint-disable-next-line no-useless-return
      // return
    }
  }

  public async get<T>(url: string, params = {}): Promise<T | undefined> {
    try {
      const response = await axiosInstance.get(url, {
        params,
        headers: this.headers
      })

      if (url.search('cashier') !== -1) {
        this.rootStore.authStore.setAuth(true)
      } else {
        this.rootStore.authStore.setAuth(
          Boolean(response.headers['session-key']) &&
            response.headers['session-key'] !== ''
        )
      }

      return response.data
    } catch (e: any) {
      console.log(e)

      this.errorHandler(e)
    }
  }

  /**
   * Used only on unprotected routes and specific requirements when setting auth / headers is not needed
   */
  public async unprotectedGet<T>(
    url: string,
    params = {}
  ): Promise<T | undefined> {
    try {
      const response = await axiosInstance.get(url, {
        params
      })

      return response.data
    } catch (e: any) {
      this.errorHandler(e)
    }
  }

  public async post<T>(
    url: string,
    params?: object,
    successNotification?: { title?: string; content: string }
  ): Promise<T | undefined> {
    try {
      const response = await axiosInstance.post<T>(
        url,
        JSON.stringify(params),
        {
          headers: this.headers
        }
      )

      if (url.search('cashier') !== -1) {
        this.rootStore.authStore.setAuth(true)
      } else {
        this.rootStore.authStore.setAuth(
          Boolean(response.headers['session-key'])
        )
      }
      if (successNotification !== undefined) {
        this.rootStore.alertsStore.addAlert({
          id: url,
          type: EAlertTypes.SUCCESS,
          title: successNotification?.title ?? 'Success!',
          content: successNotification?.content,
          timeout: 3000
        })
      }
      return response.data
    } catch (e: any) {
      console.log(e)

      this.errorHandler(e)
    }
  }

  public async delete<T>(
    url: string,
    params?: object,
    successNotification?: { title?: string; content: string }
  ): Promise<T | undefined> {
    try {
      const response = await axiosInstance.delete(url, {
        data: { ...params },
        headers: this.headers
      })
      if (url.search('cashier') !== -1) {
        this.rootStore.authStore.setAuth(true)
      } else {
        this.rootStore.authStore.setAuth(
          Boolean(response.headers['session-key'])
        )
      }
      if (successNotification !== undefined) {
        this.rootStore.alertsStore.addAlert({
          id: url,
          type: EAlertTypes.SUCCESS,
          title: successNotification?.title ?? 'Success!',
          content: successNotification?.content,
          timeout: 3000
        })
      }
      return response.data
    } catch (e: any) {
      this.errorHandler(e)
    }
  }

  public async update<T>(
    url: string,
    params?: object,
    successNotification?: { title?: string; content: string }
  ): Promise<T | undefined> {
    try {
      const response = await axiosInstance.put<T>(url, JSON.stringify(params), {
        headers: this.headers
      })

      if (url.search('cashier') !== -1) {
        this.rootStore.authStore.setAuth(true)
      } else {
        this.rootStore.authStore.setAuth(
          Boolean(response.headers['session-key'])
        )
      }
      if (successNotification !== undefined) {
        this.rootStore.alertsStore.addAlert({
          id: url,
          type: EAlertTypes.SUCCESS,
          title: successNotification?.title ?? 'Success!',
          content: successNotification?.content,
          timeout: 3000
        })
      }
      return response.data
    } catch (e: any) {
      this.errorHandler(e)
    }
  }
}

export default FetchModel
