import {
  type BalanceBorderOptions,
  type BalanceBorder as TBalanceBorder
} from 'src/models/NotificationsStore/NotificationsStore.types'
import styles from './BalanceBorder.module.scss'
import { CurrencyIcon } from 'src/ui/CurrencyIcon/CurrencyIcon'
import TextComponent from 'src/ui/TextComponent/TextComponent'
import TrashcanIcon from 'src/assets/images/svg/TrashcanIcon'

type BalanceBorderProps = {
  deleteIcon?: boolean
  onDelete?: (border: BalanceBorderOptions) => void
  borders: TBalanceBorder
  currency: string
}

const BalanceBorder = ({
  deleteIcon,
  onDelete,
  currency,
  borders
}: BalanceBorderProps) => {
  const { greater_than, lower_than } = borders

  return (
    <div className={styles.BalanceBorder}>
      <div className={styles.Currency}>
        <CurrencyIcon currency={currency} />
        <TextComponent>{currency}</TextComponent>
      </div>
      <div className={styles.Borders}>
        {greater_than != null && (
          <div className={styles.Border}>
            <TextComponent body2>Greater than</TextComponent>
            <TextComponent body2>{greater_than}</TextComponent>
            {deleteIcon === true && (
              <TrashcanIcon
                className={styles.Delete}
                onClick={() => {
                  if (onDelete !== undefined) {
                    onDelete('greater_than')
                  }
                }}
              />
            )}
          </div>
        )}
        {lower_than != null && (
          <div className={styles.Border}>
            <TextComponent body2>Lower than</TextComponent>
            <TextComponent body2>{lower_than}</TextComponent>
            {deleteIcon === true && (
              <TrashcanIcon
                className={styles.Delete}
                onClick={() => {
                  if (onDelete !== undefined) {
                    onDelete('lower_than')
                  }
                }}
              />
            )}
          </div>
        )}
      </div>
    </div>
  )
}
export default BalanceBorder
