import { type FieldError } from 'react-hook-form'
import './Input.scss'
import { forwardRef, useState, type ComponentPropsWithRef } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import AlertIcon from 'src/assets/images/svg/AlertIcon'
import { colors } from 'src/assets/scss/themes'
import { EyeOpenIcon, EyeCloseIcon } from 'src/assets/svg'
import styles from './Input.module.scss'
import { WrapperFlex } from '../Wrappers/WrapperFlex'
import TextComponent from '../TextComponent/TextComponent'
interface InputProps extends ComponentPropsWithRef<'input'> {
  label: string
  /**
   * sets width to 100%
   */
  wide?: true
  /**
   * Errors object from react-hook-form ONLY
   *
   * Will display all error messages near the placeholder as per design
   */
  errors?: FieldError | undefined
  description?: string
}
/**
 * Input styled per project requirements, used with react-hook-form
 */
const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ label, wide, errors, description, ...props }, ref) => {
    const [inputType, setInputType] = useState(props.type)

    return (
      <div>
        <div className="Input-Context">
          <WrapperFlex gap={5}>
            {label}
            <TextComponent body3 placeholder>
              {description}
            </TextComponent>
          </WrapperFlex>
          {errors?.message !== undefined && (
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>{errors.message}</Tooltip>}
            >
              <div>
                <AlertIcon fill={colors.error} />
              </div>
            </OverlayTrigger>
          )}
        </div>
        <div className={styles.Input}>
          <input
            className={`${styles.Input} Input ${wide ? '__wide' : ''} ${
              errors?.message !== undefined ? '__error' : ''
            }`}
            ref={ref}
            {...props}
            type={inputType}
          />
          {props.type === 'password' && inputType !== 'password' && (
            <EyeOpenIcon
              className={styles.PasswordVisibility}
              onClick={() => {
                setInputType('password')
              }}
            />
          )}
          {props.type === 'password' && inputType === 'password' && (
            <EyeCloseIcon
              className={styles.PasswordVisibility}
              onClick={() => {
                setInputType('text')
              }}
            />
          )}
        </div>
      </div>
    )
  }
)

Input.displayName = 'Input'
export default Input
