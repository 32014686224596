import { observer } from 'mobx-react'
import { Col, Row } from 'react-bootstrap'
import { useStore } from '../../models/Store'

const UserInfo = observer(() => {
  const { userStore } = useStore()
  return (
    <>
      <>
        <Row>
          <Col xs={4}>
            <p>E-mail:</p>
          </Col>
          <Col>{userStore.user?.email}</Col>
        </Row>
        <Row>
          <Col xs={4}>
            <p>2 Factor Authentication:</p>
          </Col>
          <Col>{userStore.user?.tfa_enabled ? 'Enabled' : 'Disabled'}</Col>
        </Row>
      </>
    </>
  )
})

export default UserInfo
