import { autorun, makeAutoObservable, runInAction } from 'mobx'
import type Store from '../Store'
import { EAlertTypes } from '../AlertsStore'
import PaginationStore from '../PaginationStore'
import { API_KEYS_VIEW_PERMISSION } from './ApiKeysStore.types'

type TGetApiKeysParams = {
  page: number
  page_size: number
  total: number
  items: IApiKey[]
}
type TGetApiKeysRequestParams = {
  page: number
  page_size: number
}

export interface IApiKey {
  id: number
  name: string
  is_enabled: boolean
  activated: string
  ips_white_list: string[]
  key: string
}

class ApiKeysStore {
  apiKeys: IApiKey[] = []
  // Leave 10 for now since pagination does not have selecting row count
  pageSize = 10
  pagination: PaginationStore
  listener: () => void = () => {}

  constructor(private readonly rootStore: Store) {
    this.rootStore = rootStore
    makeAutoObservable<ApiKeysStore, 'rootStore'>(this, {
      rootStore: false
    })
    this.pagination = new PaginationStore()
  }

  subscribe(): void {
    this.listener = autorun(() => {
      if (this.rootStore.merchantStore.currentMerchant != null) {
        void this.getApiKeys({
          page: this.pagination.currentPage,
          page_size: this.pagination.itemsPerPage
        })
      }
    })
  }

  unsubscribe(): void {
    this.listener()
    this.pagination.reset()
  }

  private setApiKeys(apiKeys: IApiKey[]): void {
    this.apiKeys = apiKeys
  }

  public async createApiKey(
    name: string,
    is_enabled: boolean,
    ips_white_list: [],
    tfa_code: string
  ): Promise<
    | {
        key: string
        secret: string
      }
    | undefined
  > {
    const response = await this.rootStore.api.post<{
      key: string
      secret: string
    }>('jsapi/api-keys', {
      name,
      is_enabled,
      ips_white_list,
      tfa_code
    })

    if (response !== undefined) {
      this.rootStore.alertsStore.addAlert({
        id: 'create-api-key',
        type: EAlertTypes.SUCCESS,
        title: 'Success!',
        content: 'API key created!',
        timeout: 5000
      })
      // Update keys when creating a new one
      void this.getApiKeys({
        page: this.pagination.currentPage,
        page_size: this.pagination.itemsPerPage
      })
    }
    return response
  }

  public async getApiKeys({
    page,
    page_size
  }: TGetApiKeysRequestParams): Promise<void> {
    if (
      !this.rootStore.initialStore.getIsPermissionEnabled(
        API_KEYS_VIEW_PERMISSION
      )
    ) {
      return
    }
    const response = await this.rootStore.api.get<TGetApiKeysParams>(
      'jsapi/api-keys',
      { page, page_size }
    )

    if (response !== undefined) {
      runInAction(() => {
        this.pagination.init(this.pagination.itemsPerPage, response.total)
      })
      this.setApiKeys(response.items)
    }
  }

  public async getSingleApiKey(id: number): Promise<IApiKey | undefined> {
    return await this.rootStore.api.get<IApiKey>(`jsapi/api-keys/${id}`)
  }

  public async updateApiKey(
    id: number,
    tfa_code: string,
    {
      name,
      is_enabled,
      ips_white_list
    }: {
      name?: string
      is_enabled?: boolean
      ips_white_list?: string[]
    }
  ): Promise<IApiKey | undefined> {
    const response = await this.rootStore.api.update<IApiKey>(
      `jsapi/api-keys/${id}`,
      {
        tfa_code,
        name,
        is_enabled,
        ips_white_list
      }
    )
    if (response !== undefined) {
      this.rootStore.alertsStore.addAlert({
        id: 'update-api-key',
        type: EAlertTypes.SUCCESS,
        title: 'Success!',
        content: 'API key updated!',
        timeout: 5000
      })
    }
    await this.getApiKeys({
      page: this.pagination.currentPage,
      page_size: this.pagination.itemsPerPage
    })
    return response
  }

  public async deleteApiKey(
    id: number,
    tfa_code: string
  ): Promise<IApiKey | undefined> {
    const response = await this.rootStore.api.delete<IApiKey>(
      `jsapi/api-keys/${id}`,
      {
        tfa_code
      }
    )
    if (response !== undefined) {
      this.rootStore.alertsStore.addAlert({
        id: 'delete-api-key',
        type: EAlertTypes.SUCCESS,
        title: 'Success!',
        content: 'API key deleted!',
        timeout: 5000
      })
    }
    await this.getApiKeys({
      page: this.pagination.currentPage,
      page_size: this.pagination.itemsPerPage
    })
    return response
  }
}

export default ApiKeysStore
