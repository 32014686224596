import {
  type ComponentPropsWithoutRef,
  forwardRef,
  type PropsWithChildren
} from 'react'
import styles from './TextComponent.module.scss'
import classNames from 'classnames'

type TextComponentProps = {
  bold?: boolean
  body2?: boolean
  body3?: boolean
  body4?: boolean
  capitalize?: boolean
  centered?: boolean
  light?: boolean
  placeholder?: boolean
} & PropsWithChildren &
  Pick<ComponentPropsWithoutRef<'p'>, 'className'>

/**
 * Body 1 by default
 */
const TextComponent = forwardRef<HTMLParagraphElement, TextComponentProps>(
  (
    {
      body2,
      body3,
      body4,
      bold,
      children,
      capitalize,
      centered,
      light,
      placeholder,
      className
    },
    ref
  ) => {
    const textComponentClassnames = classNames(
      styles.TextComponent,
      {
        [styles.Bold]: bold,
        [styles.Body4]: body4,
        [styles.Body3]: body3,
        [styles.Body2]: body2,
        [styles.Capitalize]: capitalize,
        [styles.Centered]: centered,
        [styles.Light]: light,
        [styles.Placeholder]: placeholder
      },
      className
    )
    return (
      <p className={textComponentClassnames} ref={ref}>
        {children}
      </p>
    )
  }
)

TextComponent.displayName = 'TextComponent'
export default TextComponent
