import { observer } from 'mobx-react'
import { Col, Row } from 'react-bootstrap'
import { useStore } from '../../models/Store'
import { NavLink, Outlet } from 'react-router-dom'
import { NOTIFICATION_SETTINGS_PERMISSION } from 'src/models/NotificationsStore/NotificationsStore.types'

const Profile = observer(() => {
  const { initialStore } = useStore()

  return (
    <>
      <Row
        style={{
          alignItems: 'center',
          marginBlock: 30
        }}
      >
        <Col xs={'auto'}>
          <div className={'nav-pills'}>
            <NavLink
              to={'info'}
              className={({ isActive }) => (isActive ? 'pill active' : 'pill')}
            >
              Profile
            </NavLink>
            <NavLink
              to={'security'}
              className={({ isActive }) => (isActive ? 'pill active' : 'pill')}
            >
              Security
            </NavLink>
            {initialStore.getIsPermissionEnabled(
              NOTIFICATION_SETTINGS_PERMISSION
            ) && (
              <NavLink
                to={'notifications'}
                className={({ isActive }) =>
                  isActive ? 'pill active' : 'pill'
                }
              >
                Notifications
              </NavLink>
            )}
          </div>
        </Col>
      </Row>
      <Outlet />
    </>
  )
})

export default Profile
