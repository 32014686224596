import { type PropsWithChildren } from 'react'
import styles from './Wrappers.module.scss'

type WrapperFlexBetweenProps = {
  gap?: number
  marginBlock?: number
} & PropsWithChildren

export const WrapperFlexBetween = ({
  gap,
  marginBlock,
  children
}: WrapperFlexBetweenProps) => {
  return (
    <div className={styles.WrapperFlexBetween} style={{ gap, marginBlock }}>
      {children}
    </div>
  )
}
