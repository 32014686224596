import MainMenu from '../../ui/Menu'
import colors from '../../assets/scss/themes/colors.module.scss'
import { Dropdown } from 'react-bootstrap'
import ProfileIcon from '../../assets/images/svg/userprofile.svg'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../models/Store'
import ArrowIcon from 'src/assets/images/svg/ArrowIcon'
import BalancesIcon from '../../assets/images/svg/navigation-balances.svg'
import TransactionsIcon from '../../assets/images/svg/navigation-transactions.svg'
import ReportsIcon from '../../assets/images/svg/navigation-reports.svg'
import AddressesIcon from '../../assets/images/svg/navigation-addresses.svg'
import FeesIcon from '../../assets/images/svg/navigation-fees.svg'
import CitronLogo from 'src/assets/images/CitronLogoYellow.svg'
import MerchantsDropdown from '../MerchantsDropdown/MerchantsDropdown'
import styles from './Header.module.scss'
import { EyeOpenIcon, NotificationBellIcon } from 'src/assets/svg'
import { RISK_CHECK_PERMISSION } from 'src/models/RiskCheckStore/RiskCheckStore.types'
import { Switch } from 'src/ui/Switch/Switch'
import { NOTIFICATION_SETTINGS_PERMISSION } from 'src/models/NotificationsStore/NotificationsStore.types'
import { runInAction } from 'mobx'
import Button, { EButtonType } from 'src/ui/Button'
import TextComponent from 'src/ui/TextComponent/TextComponent'
import BasicDropdown from 'src/ui/Dropdowns/BasicDropdown/BasicDropdown'
import classNames from 'classnames'
import { useDetectClickOutside } from 'react-detect-click-outside'
import { useState } from 'react'
import { formatDate } from 'src/utils/helpers/formatDate'
const menu = [
  {
    title: 'Balances',
    link: 'balances',
    end: false,
    permisssion: 'balances_view',
    icon: <BalancesIcon />
  },
  {
    title: 'Transactions',
    link: 'transactions',
    permission: 'transactions_view',
    end: false,
    icon: <TransactionsIcon />
  },
  {
    title: 'Risk Check',
    link: 'risk-check',
    permission: RISK_CHECK_PERMISSION,
    end: false,
    icon: <EyeOpenIcon />
  },
  {
    title: 'Reports',
    link: 'reports',
    permission: 'merchants_reports_view',
    end: false,
    icon: <ReportsIcon />
  },
  {
    title: 'Addresses & Bank Accounts',
    link: 'addresses',
    permission: 'addresses_view',
    end: false,
    icon: <AddressesIcon />
  },
  {
    title: 'Fees',
    link: 'fees',
    end: false,
    icon: <FeesIcon />
  }
  // {
  //   title: 'Team',
  //   link: 'team'
  // }
]
const Header = observer(() => {
  const store = useStore()
  const { notificationsStore } = store
  const location = useLocation()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const ref = useDetectClickOutside({
    onTriggered: () => {
      setIsDropdownOpen(false)
    }
  })

  const isCurrentlyOnProfilePage = location.pathname.includes('/profile')

  return (
    <div className={styles.Header + ' ' + 'header'}>
      <div className={styles.LogoWithMerchants}>
        <div>
          <CitronLogo />
        </div>
        <MerchantsDropdown />
      </div>
      <MainMenu
        items={menu.filter(({ permission }) => {
          if (permission === undefined) {
            return true
          }
          return store.initialStore.getIsPermissionEnabled(permission)
        })}
      />
      <div className="header-navbar __mobile">
        {menu
          .filter(({ permission }) => {
            if (permission === undefined) {
              return true
            }
            return store.initialStore.getIsPermissionEnabled(permission)
          })
          .map((menuItem) => {
            return (
              <div className="header-navbar-item" key={menuItem.title}>
                <NavLink to={menuItem.link}>
                  {menuItem.icon}
                  <div>{menuItem.title}</div>
                </NavLink>
              </div>
            )
          })}
      </div>
      <div
        className={'left header-column align-items-center'}
        style={{ marginLeft: 'auto' }}
      >
        <p
          style={{ marginBottom: 0, marginLeft: 10 }}
          className="header-userEmail"
        >
          {store.userStore.user?.email}
        </p>
        <Dropdown>
          <Dropdown.Toggle className={'icon-dd'} size={'sm'}>
            <ProfileIcon
              className={`profile-icon${
                isCurrentlyOnProfilePage ? '--active' : ''
              }`}
            />
          </Dropdown.Toggle>
          <Dropdown.Menu variant={'dark'}>
            <Dropdown.Item as={Link} to="/profile">
              Profile
            </Dropdown.Item>
            <Dropdown.Item as={Link} to="/profile/security">
              Security
            </Dropdown.Item>
            {store.initialStore.getIsPermissionEnabled(
              NOTIFICATION_SETTINGS_PERMISSION
            ) && (
              <Dropdown.Item as={Link} to="/profile/notifications">
                Notifications
              </Dropdown.Item>
            )}
            <Dropdown.Item href="https://docs.citrn.io/">
              Documentation
            </Dropdown.Item>
            <Dropdown.Item href="mailto:support@citrn.io">
              Support
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item
              as={Link}
              to={'/login'}
              onClick={() => {
                void store.userStore.logout()
              }}
              style={{
                height: '45px',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: colors.main,
                color: colors.bg
              }}
            >
              <div>
                <ArrowIcon fill={colors.bg} />
                Logout
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        {store.initialStore.getIsPermissionEnabled(
          NOTIFICATION_SETTINGS_PERMISSION
        ) && (
          <div className={styles.Dropdown} ref={ref}>
            <NotificationBellIcon
              className={classNames(styles.NotificationToggle, {
                [styles.Active]: isDropdownOpen
              })}
              onClick={() => {
                setIsDropdownOpen(!isDropdownOpen)
              }}
            />
            <div
              className={classNames(styles.RedBlob, {
                [styles.Visible]:
                  notificationsStore.notifications.notifications.find(
                    (notif) => notif.is_new
                  )
              })}
            />
            <BasicDropdown isOpen={isDropdownOpen}>
              <div className={styles.NotificationDropdownHeader}>
                <Switch
                  checked={notificationsStore.onlyNewNotifications}
                  onChange={(checked) => {
                    runInAction(() => {
                      notificationsStore.onlyNewNotifications = checked
                    })
                  }}
                />
                <TextComponent body2>Only new notifications</TextComponent>
                <Button
                  title="Clear all"
                  buttonType={EButtonType.LINK}
                  onClick={() => {
                    notificationsStore.clearNotifications()
                  }}
                />
                <NavLink
                  className={styles.Link}
                  to={'/profile/notifications'}
                  onClick={() => {
                    setIsDropdownOpen(false)
                  }}
                >
                  <TextComponent body3>Settings</TextComponent>
                </NavLink>
              </div>
              <ul>
                {notificationsStore.notifications.notifications.length < 1 && (
                  <TextComponent body2 centered>
                    No new notifications
                  </TextComponent>
                )}
                {notificationsStore.notifications.notifications.map(
                  ({ message, created_at, is_new }) => (
                    <li
                      className={classNames({ [styles.IsNew]: is_new })}
                      key={created_at}
                    >
                      <TextComponent body2>{message}</TextComponent>
                      <TextComponent placeholder body4>
                        {formatDate(new Date(created_at))}
                      </TextComponent>
                    </li>
                  )
                )}
              </ul>
            </BasicDropdown>
          </div>
        )}
      </div>
    </div>
  )
})

export default Header
