import styles from './NotificationsPage.module.scss'
import TextComponent from 'src/ui/TextComponent/TextComponent'
import { observer } from 'mobx-react'
import { useStore } from 'src/models/Store'
import { useEffect } from 'react'
import { Switch } from 'src/ui/Switch/Switch'
import { Outlet, useNavigate } from 'react-router-dom'
import { WrapperColumn } from 'src/ui/Wrappers/WrapperColumn'
import { type NotificationSetting } from 'src/models/NotificationsStore/NotificationsStore.types'
import Button from 'src/ui/Button'
import Checkbox from 'src/ui/Checkbox/Checkbox'

export const NotificationsPage = observer(() => {
  const { notificationsStore, merchantStore } = useStore()
  const { notificationSettings, notificationChannels } = notificationsStore
  const navigate = useNavigate()

  useEffect(() => {
    notificationsStore.subscribe()
    return () => {
      notificationsStore.unsubscribe()
    }
  }, [merchantStore.currentMerchant])

  const handleSwitchChange = async (
    enabled: boolean,
    setting: NotificationSetting
  ) => {
    const newSetting = { ...setting, enabled }
    await notificationsStore.updateNotificationSetting(newSetting)
  }

  return (
    <div className={styles.NotificationsPage}>
      <div className={styles.Heading}>
        <div className={styles.NotificationsHeading}>
          <h3> Notifications settings</h3>
          <TextComponent>
            Customize your notification preferences effortlessly.
          </TextComponent>
          <TextComponent>Manage which updates you receive.</TextComponent>
        </div>
        <div className={styles.AlertChannelsHeading}>
          <h3>Alert delivery channels</h3>
          <Button
            title="Manage"
            onClick={() => {
              navigate('manage-alert-channels')
            }}
          />
        </div>
      </div>
      <div className={styles.Settings}>
        {notificationSettings.map((setting) => {
          const {
            enabled,
            id,
            type,
            name,
            description,
            params: { channels }
          } = setting
          return (
            <div key={id} className={styles.Setting}>
              <div className={styles.Type}>
                <WrapperColumn>
                  <Switch
                    checked={enabled}
                    onChange={async (checked) => {
                      await handleSwitchChange(checked, setting)
                    }}
                  />
                  {type === 'max_min_balance' && (
                    <Button
                      onClick={() => {
                        navigate('balance-borders/' + id.toString())
                      }}
                      classes={styles.SetupBorderButton}
                      title="Setup"
                    />
                  )}
                </WrapperColumn>
                <WrapperColumn gap={0}>
                  <TextComponent>
                    {name}{' '}
                    {/* {type === 'max_min_balance' && (
                      <span className={styles.BorderCount}>
                        {notificationSettings[index].params.borders.length}
                      </span>
                    )} */}
                  </TextComponent>
                  <TextComponent
                    placeholder
                    body3
                    className={styles.Description}
                  >
                    {description}
                  </TextComponent>
                </WrapperColumn>
              </div>
              <div className={styles.Channels}>
                {Object.entries(notificationChannels)
                  .filter(([_, { enabled }]) => enabled)
                  .map(([channelIdName, { name }]) => (
                    <div key={channelIdName} className={styles.AlertChannels}>
                      <TextComponent>{name}</TextComponent>
                      <Checkbox
                        checked={channels.some(
                          (enabledChannel) => enabledChannel === channelIdName
                        )}
                        onChange={async ({ target: { checked } }) =>
                          await notificationsStore.editChannelEnabled(
                            setting,
                            channelIdName,
                            checked
                          )
                        }
                      />
                    </div>
                  ))}
              </div>
            </div>
          )
        })}
      </div>
      <Outlet />
    </div>
  )
})
