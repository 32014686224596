import { observer } from 'mobx-react'
import { type TMerchant } from 'src/models/MerchantStore'
import styles from './MerchantsDropdown.module.scss'
import { useStore } from 'src/models/Store'
import { useRef, useState } from 'react'
import TextComponent from 'src/ui/TextComponent/TextComponent'
import classNames from 'classnames'
type MerchantDropdownItemProps = {
  merchant: TMerchant
  onClick: () => void
}

const MerchantDropdownItem = observer(
  ({ merchant, onClick }: MerchantDropdownItemProps) => {
    const { merchantStore } = useStore()
    const merchantContainerRef = useRef<HTMLDivElement>(null)
    const textRef = useRef<HTMLParagraphElement>(null)
    const [scroll, setScroll] = useState(false)

    const classnames = classNames(styles.RunningMerchantName, {
      [styles.Scroll]: scroll
    })

    const handleShouldLongNameScroll = () => {
      if (merchantContainerRef.current != null && textRef.current != null) {
        if (
          merchantContainerRef.current.clientWidth < textRef.current.clientWidth
        ) {
          setScroll(true)
          return true
        }
      }
      return false
    }
    return (
      <div
        className={classNames(styles.DropdownItem, {
          [styles.Active]: merchant.id === merchantStore.currentMerchant?.id
        })}
        key={merchant.id}
        onClick={onClick}
        onMouseEnter={() => {
          handleShouldLongNameScroll()
        }}
        onMouseLeave={() => {
          setScroll(false)
        }}
        ref={merchantContainerRef}
      >
        <div className={classnames}>
          <TextComponent ref={textRef}>{merchant.name}</TextComponent>
        </div>
      </div>
    )
  }
)
export default MerchantDropdownItem
