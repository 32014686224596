import React from 'react'
import { observer } from 'mobx-react'
import Button, { EButtonType } from 'src/ui/Button'
import { SettingsIcon } from 'src/assets/svg'
import BaseSideBarComponent from 'src/components/BaseSideBarComponent/BaseSideBarComponent'
import TextComponent from 'src/ui/TextComponent/TextComponent'
import { WrapperColumn } from 'src/ui/Wrappers/WrapperColumn'
import { WrapperFlexBetween } from 'src/ui/Wrappers/WrapperFlexBetween'
import BalanceBorder from '../Components/BalanceBorder/BalanceBorder'
import { useStore } from 'src/models/Store'
import { useNavigate } from 'react-router-dom'
import useFormStepper from 'src/utils/hooks/useFormStepper'
import NotificationsDeleteBorders from './NotificationsDeleteBorders/NotificationsDeleteBorders'
import {
  type BalanceBorder as TBalanceBorder,
  type BalanceBorderOptions
} from 'src/models/NotificationsStore/NotificationsStore.types'

const NotificationsManageBorders = observer(() => {
  const [selectedBorder, setSelectedBorder] = React.useState<{
    toDelete: BalanceBorderOptions
    currency: string
    border: TBalanceBorder
  } | null>(null)
  const {
    notificationsStore: { balanceBorders }
  } = useStore()
  const navigate = useNavigate()

  const { currentStepElement, moveToNextStep, moveStepBack } = useFormStepper([
    <>
      <WrapperColumn gap={20}>
        {Object.keys(balanceBorders).length < 1 ? (
          <TextComponent centered>No borders added.</TextComponent>
        ) : (
          Object.entries(balanceBorders).map(([currency, borders]) => (
            <BalanceBorder
              key={currency}
              currency={currency}
              borders={borders}
              deleteIcon
              onDelete={(borderOption) => {
                setSelectedBorder({
                  border: { [borderOption]: borders[borderOption] },
                  currency,
                  toDelete: borderOption
                })
                moveToNextStep()
              }}
            />
          ))
        )}
      </WrapperColumn>
      <WrapperFlexBetween marginBlock={40}>
        <Button
          title="Done"
          wide
          onClick={() => {
            navigate('..')
          }}
        />
        <Button
          title="Cancel"
          wide
          buttonType={EButtonType.SECONDARY}
          onClick={() => {
            navigate('..')
          }}
        />
      </WrapperFlexBetween>
    </>,
    <NotificationsDeleteBorders
      key={1}
      border={selectedBorder != null ? { ...selectedBorder } : null}
      onCancel={() => {
        setSelectedBorder(null)
        moveStepBack()
      }}
      onDelete={() => {
        moveStepBack()
      }}
    />
  ])

  return (
    <BaseSideBarComponent
      headerTitle="Manage borders"
      headerIcon={<SettingsIcon />}
    >
      {currentStepElement}
    </BaseSideBarComponent>
  )
})
export default NotificationsManageBorders
