import { type PropsWithChildren } from 'react'
import styles from './Wrappers.module.scss'

type WrapperColumnProps = {
  gap?: number
  marginBlock?: number
} & PropsWithChildren

export const WrapperColumn = ({
  gap = 10,
  marginBlock,
  children
}: WrapperColumnProps) => {
  return (
    <div className={styles.WrapperColumn} style={{ gap, marginBlock }}>
      {children}
    </div>
  )
}
