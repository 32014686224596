import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { ProtectedPage, SideBarModal } from '../ui/Layout/Layout'
import LoginPage from '../pages/LoginPage/LoginPage'
import BalancesPage from '../pages/BalancesPage/BalancesPage'
import AddressesPage from '../pages/AddressesPage/AddressesPage'
import PayinPage from '../pages/PayinPage/PayinPage'
import FeesPage, { B2b, C2b } from '../pages/Fees/FeesPage'
import Profile from '../pages/Profile/Profile'
import TransactionInfo from '../pages/TransactionsPage/TransactionInfo'
import UserInfo from '../pages/Profile/UserInfo'
import Security from '../pages/Profile/Security'
import Setup2fa from '../pages/Profile/Setup2fa'
import BusinessSignUp from '../pages/BusinessSignUp/BusinessSignUp'
import Balance from '../pages/BalancesPage/Balance'
import AlertsList from '../ui/Alerts/AlertsList'
import { observer } from 'mobx-react'
import ReportsPage from '../pages/ReportsPage/ReportsPage'
import TransactionReports from 'src/pages/ReportsPage/TransactionReports/TransactionReports'
import BalanceReports from 'src/pages/ReportsPage/BalanceReports/BalanceReports'
import TeamPage from 'src/pages/TeamPage/TeamPage'
import CreateApiKey from 'src/components/Modals/ApiKeysPageModals/CreateApiKey/CreateApiKey'
import EditApiKey from 'src/components/Modals/ApiKeysPageModals/EditApiKey/EditApiKey'
import DeleteApiKey from 'src/components/Modals/ApiKeysPageModals/DeleteApiKey/DeleteApiKey'
import DisableApiKey from 'src/components/Modals/ApiKeysPageModals/DisableApiKey/DisableApiKey'
import WithdrawPage from 'src/pages/WithdrawPage/WithdrawPage'
import WithdrawAddressesAddNew from 'src/pages/WithdrawPage/WithdrawAddresses/WithdrawAddressesAddNew'
import WitdrawAddressesDelete from 'src/pages/WithdrawPage/WithdrawAddresses/WitdrawAddressesDelete'
import WithdrawAddressesManage from 'src/pages/WithdrawPage/WithdrawAddresses/WithdrawAddressesManage'
import WithdrawAddressesEdit from 'src/pages/WithdrawPage/WithdrawAddresses/WithdrawAddressesEdit'
import CustomAddressesPage from 'src/pages/AddressesPage/CustomAddressesPage/CustomAddressesPage'
import SystemAddressesPage from 'src/pages/AddressesPage/SystemAddressesPage/SystemAddressesPage'
import PasswordResetPage from 'src/pages/PasswordResetPage/PasswordResetPage'
import UnprotectedPage from 'src/ui/Layout/UnprotectedPage/UnprotectedPage'
import PasswordResetSuccess from 'src/pages/PasswordResetPage/PasswordResetSteps/PasswordResetSuccess'
import NewPasswordAfterReset from 'src/pages/PasswordResetPage/PasswordResetSteps/NewPasswordAfterReset'
import TransactionsLayout from '../pages/TransactionsPage/TransactionsLayout'
import NoRouterSideBarModal from 'src/components/SideBarModal/SideBarModal'
import ProfileChangePassword from 'src/pages/Profile/ProfileChangePassword/ProfileChangePassword'
import TopUpFiatPage from 'src/pages/TopUpFiatPage/TopUpFiatPage'
import WithdrawFiatPage from 'src/pages/WithdrawFiatPage/WithdrawFiatPage'
import NewFiatWithdrawAccount from '../pages/NewFiatWithdrawAccount/NewFiatWithdrawAccount'
import FiatPayout from '../pages/FiatPayout/FiatPayout'
import FiatAddressesPage from 'src/pages/AddressesPage/FiatAddressesPage/FiatAddressesPage'
import FiatAddressesEdit from 'src/pages/AddressesPage/FiatAddressesPage/FiatAddressesManage/FiatAddressesEdit'
import FiatAddressesDelete from 'src/pages/AddressesPage/FiatAddressesPage/FiatAddressesManage/FiatAddressesDelete'
import FiatAddressesAdd from 'src/pages/AddressesPage/FiatAddressesPage/FiatAddressesManage/FiatAddressesAdd'
import RedirectPaymentsPage from 'src/pages/RedirectPaymentPage/RedirectPaymentsPage'
import TransactionsList from 'src/pages/TransactionsPage/TransactionsList/TransactionsList'
import RiskCheckPage from 'src/pages/RiskCheckPage/RiskCheckPage'
import { NotificationsPage } from 'src/pages/Profile/NotificationsPage/NotificationsPage'
import { RiskCheckAddress } from 'src/pages/RiskCheckPage/RiskCheckAddress/RiskCheckAddress'
import NotificationsManageChannels from 'src/pages/Profile/NotificationsPage/NotificationsManageChannels/NotificationsManageChannels'
import NotificationsBalanceBorders from 'src/pages/Profile/NotificationsPage/NotificationsBalanceBorders/NotificationsBalanceBorders'
import NotificationsAddBorder from 'src/pages/Profile/NotificationsPage/NotificationsBalanceBorders/NotificationsAddBorder/NotificationsAddBorder'
import NotificationsManageBorders from 'src/pages/Profile/NotificationsPage/NotificationsBalanceBorders/NotificationsManageBorders/NotificationsManageBorders'

const Router = observer(() => {
  return (
    <>
      <AlertsList />
      <BrowserRouter>
        <NoRouterSideBarModal />
        <Routes>
          <Route path={'/business-sign-up'} element={<BusinessSignUp />} />
          <Route path={'/login'} element={<UnprotectedPage />}>
            <Route index element={<LoginPage />} />
          </Route>
          <Route path={'/reset-password'} element={<UnprotectedPage />}>
            <Route index element={<PasswordResetPage />} />
            <Route path={'success'} element={<PasswordResetSuccess />} />
            <Route path={'new-password'} element={<NewPasswordAfterReset />} />
          </Route>
          <Route
            path={'/redirect-payment/:id'}
            element={<RedirectPaymentsPage />}
          />
          <Route index element={<Navigate to={'balances/all'} replace />} />
          <Route element={<ProtectedPage />}>
            <Route path={'balances'} element={<BalancesPage />}>
              <Route index element={<Navigate to={'all'} replace />} />
              <Route path={':page'} element={<Balance />}>
                <Route path={'topup'} element={<SideBarModal />}>
                  <Route index path={':balanceId'} element={<PayinPage />} />
                </Route>
                <Route path={'topup-fiat'} element={<SideBarModal />}>
                  <Route index path={':currency'} element={<TopUpFiatPage />} />
                </Route>
                <Route path={'withdraw-fiat'} element={<SideBarModal />}>
                  <Route index path={':id'} element={<WithdrawFiatPage />} />
                </Route>
                <Route path={'fiat-payout'} element={<SideBarModal />}>
                  <Route index path={':id/:account'} element={<FiatPayout />} />
                </Route>
                <Route
                  path={'new-withdraw-fiat-acc'}
                  element={<SideBarModal />}
                >
                  <Route
                    index
                    path={':id'}
                    element={<NewFiatWithdrawAccount />}
                  />
                </Route>
                <Route path={'withdraw/:balanceId'} element={<SideBarModal />}>
                  <Route index element={<WithdrawPage />} />
                  <Route
                    path={'add'}
                    element={
                      <WithdrawAddressesAddNew navigateAfterSubmit=".." />
                    }
                  />
                  <Route
                    path={'manage/:networkId'}
                    element={<WithdrawAddressesManage />}
                  />
                  <Route
                    path={'manage/:networkId/delete/address/:addressId'}
                    element={<WitdrawAddressesDelete />}
                  />
                  <Route
                    path={'manage/:networkId/edit/address/:addressId'}
                    element={<WithdrawAddressesEdit />}
                  />
                </Route>
              </Route>
            </Route>
            <Route path={'transactions'} element={<TransactionsLayout />}>
              <Route index element={<Navigate to={'all'} replace />} />
              <Route path={':page'} element={<TransactionsList />}>
                <Route path={'info'} element={<SideBarModal />}>
                  <Route index path={':id'} element={<TransactionInfo />} />
                </Route>
              </Route>
            </Route>
            <Route path={'addresses'} element={<AddressesPage />}>
              <Route index element={<SystemAddressesPage />} />
              <Route path={'customAddresses'} element={<CustomAddressesPage />}>
                <Route path={'manage'} element={<SideBarModal />}>
                  <Route
                    path={'add'}
                    element={
                      <WithdrawAddressesAddNew navigateAfterSubmit="../.." />
                    }
                  />
                  <Route
                    path={'edit/address/:addressId'}
                    element={<WithdrawAddressesEdit />}
                  />
                  <Route
                    path={'delete/address/:addressId'}
                    element={<WitdrawAddressesDelete />}
                  />
                </Route>
              </Route>
              <Route path={'fiat-addresses'} element={<FiatAddressesPage />}>
                <Route path={'manage'} element={<SideBarModal />}>
                  <Route path={'add'} element={<FiatAddressesAdd />} />
                  <Route path={'edit/:id'} element={<FiatAddressesEdit />} />
                  <Route
                    path={'delete/:id'}
                    element={<FiatAddressesDelete />}
                  />
                </Route>
              </Route>
            </Route>
            <Route path={'fees'} element={<FeesPage />}>
              <Route index element={<Navigate replace to="c2b" />} />
              <Route path={'c2b'} element={<C2b />} />
              <Route path={'b2b'} element={<B2b />} />
            </Route>
            <Route path={'profile'} element={<Profile />}>
              <Route index element={<Navigate replace to="info" />} />
              <Route path={'info'} element={<UserInfo />} />
              <Route path={'security'} element={<Security />}>
                <Route path="password-change" element={<SideBarModal />}>
                  <Route index element={<ProfileChangePassword />} />
                </Route>
                <Route path={'2fa'} element={<SideBarModal />}>
                  <Route index path={'setup'} element={<Setup2fa />} />
                </Route>
                <Route path={'apikeys'} element={<SideBarModal />}>
                  <Route path={'create'} element={<CreateApiKey />} />
                  <Route path={'manage/:id'} element={<EditApiKey />} />
                  <Route path={'delete/:id'} element={<DeleteApiKey />} />
                  <Route path={'disable/:id'} element={<DisableApiKey />} />
                </Route>
              </Route>
              <Route path={'notifications'} element={<NotificationsPage />}>
                <Route
                  path="manage-alert-channels"
                  element={
                    <SideBarModal>
                      <NotificationsManageChannels />
                    </SideBarModal>
                  }
                />
                <Route path="balance-borders/:id" element={<SideBarModal />}>
                  <Route index element={<NotificationsBalanceBorders />} />
                  <Route
                    path="add-border"
                    element={<NotificationsAddBorder />}
                  />
                  <Route
                    path="manage"
                    element={<NotificationsManageBorders />}
                  />
                </Route>
              </Route>
            </Route>

            <Route path={'reports'} element={<ReportsPage />}>
              <Route index element={<TransactionReports />} />
              <Route path={'balance-reports'} element={<BalanceReports />} />
            </Route>
            <Route path={'team'} element={<TeamPage />} />
            <Route path="risk-check" element={<RiskCheckPage />}>
              <Route path="check-address" element={<SideBarModal />}>
                <Route index element={<RiskCheckAddress />} />
              </Route>
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  )
})

export default Router
