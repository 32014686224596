import type React from 'react'
import '../ApiKeysPageModals.scss'
import { observer } from 'mobx-react'
import BaseSideBarComponent from 'src/components/BaseSideBarComponent/BaseSideBarComponent'
import LinkIcon from 'src/assets/images/svg/LinkIcon'
import Input from 'src/ui/Input/Input'
import { type SubmitHandler, useForm } from 'react-hook-form'
import Button, { EButtonType } from 'src/ui/Button'
import { useState } from 'react'
import OTPCodeInput from '../OTPCodeInput/OTPCodeInput'
import { useStore } from 'src/models/Store'
import { useLocation } from 'react-router-dom'
import { type IApiKey } from 'src/models/ApiKeysStore/ApiKeysStore'

type FormInputs = {
  tfa_code: string
}

const DeleteApiKey: React.FC = observer(() => {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting }
  } = useForm<FormInputs>({
    defaultValues: async () => {
      const response = await apiKeysStore.getSingleApiKey(location.state.id)
      setSelectedApiKey(response)
      return { tfa_code: '' }
    }
  })

  const [formStep, setFormStep] = useState<'keyDeletion' | '2FA'>('keyDeletion')
  const [selectedApiKey, setSelectedApiKey] = useState<IApiKey>()
  const { apiKeysStore, utilityStore } = useStore()
  const location = useLocation()

  const apiKeyId = location.state.id

  const onSubmit: SubmitHandler<FormInputs> = async ({ tfa_code }) => {
    const response = await apiKeysStore.deleteApiKey(apiKeyId, tfa_code)
    if (response !== undefined) {
      utilityStore.closeSideBarModal()
    }
  }

  const renderFormStep = (): JSX.Element => {
    if (formStep === 'keyDeletion') {
      return (
        <>
          <h2>Are you sure you want to delete this API key?</h2>
          <Input
            label="Key name"
            disabled
            defaultValue={selectedApiKey?.name}
          />
          <div className="DeleteApiKey-ButtonGroup">
            <Button
              title="Delete API key"
              onClick={() => {
                setFormStep('2FA')
              }}
            />
            <Button
              title="Cancel"
              buttonType={EButtonType.SECONDARY}
              onClick={() => {
                utilityStore.closeSideBarModal()
              }}
            />
          </div>
        </>
      )
    }
    if (formStep === '2FA') {
      return (
        <OTPCodeInput
          control={control}
          label="Please enter the sign-in 2FA code from your authenticator app"
          onSubmit={handleSubmit(onSubmit)}
          isSubmitting={isSubmitting}
          onCancel={() => {
            utilityStore.closeSideBarModal()
          }}
        />
      )
    }
    return <div>Something went wrong, please contact support</div>
  }

  return (
    <BaseSideBarComponent
      headerTitle="Delete API key"
      headerIcon={<LinkIcon />}
      checkTFAEnabled
    >
      <div className="DeleteApiKey">{renderFormStep()}</div>
    </BaseSideBarComponent>
  )
})
export default DeleteApiKey
