export type RiskCheckAddress = {
  address: string
  is_risky: boolean
  risk_percent: number
  datetime: string
  risk_level: string
}

export type RiskCheckPageFilters = { page: number; page_size: number }

export const RISK_CHECK_PERMISSION = 'risk_check_view'
