import { observer } from 'mobx-react'
import { useState } from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { EyeOpenIcon } from 'src/assets/svg'
import BaseSideBarComponent from 'src/components/BaseSideBarComponent/BaseSideBarComponent'
import { type RiskCheckAddress as TRiskCheckAddress } from 'src/models/RiskCheckStore/RiskCheckStore.types'
import { useStore } from 'src/models/Store'
import Button, { EButtonType } from 'src/ui/Button'
import Input from 'src/ui/Input/Input'
import { WrapperFlexBetween } from 'src/ui/Wrappers/WrapperFlexBetween'
import useFormStepper from 'src/utils/hooks/useFormStepper'
import styles from '../RiskCheckPage.module.scss'
import classNames from 'classnames'
import { WrapperFlex } from 'src/ui'
import TextComponent from 'src/ui/TextComponent/TextComponent'

export const RiskCheckAddress = observer(() => {
  const [checkedAddress, setCheckedAddress] =
    useState<TRiskCheckAddress | null>(null)

  const { utilityStore, riskCheckStore } = useStore()
  const form = useForm<{ address: string }>()
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = form
  const onSubmit: SubmitHandler<{ address: string }> = async ({ address }) => {
    const response = await riskCheckStore.riskCheckAddress(address)
    if (response !== undefined) {
      setCheckedAddress(response)
      moveToNextStep()
    }
  }

  const { moveToNextStep, currentStepElement } = useFormStepper([
    <>
      <h3>Check Address</h3>
      <form>
        <Input
          wide
          label="Address"
          errors={errors.address}
          {...register('address', {
            required: { value: true, message: 'Field required' }
          })}
        />

        <WrapperFlexBetween marginBlock={20}>
          <Button
            wide
            title="Check"
            type="submit"
            onClick={handleSubmit(onSubmit)}
            isLoading={isSubmitting}
          />
          <Button
            wide
            title="Cancel"
            type="button"
            buttonType={EButtonType.SECONDARY}
            onClick={() => {
              utilityStore.closeSideBarModal()
            }}
          />
        </WrapperFlexBetween>
      </form>
    </>,
    <>
      <h3>Address has been checked</h3>
      <Input wide disabled label="Address" value={checkedAddress?.address} />
      <WrapperFlex gap={10} marginBlock={10}>
        <TextComponent>Risk score</TextComponent>
        <div
          className={classNames(styles.RiskPercent, {
            [styles.Low]: (checkedAddress?.risk_percent ?? 0) < 1,
            [styles.High]: (checkedAddress?.risk_percent ?? 0) >= 1
          })}
        >
          {checkedAddress?.risk_percent}%
        </div>
      </WrapperFlex>
      <WrapperFlexBetween marginBlock={20}>
        <Button
          wide
          title="Done"
          onClick={() => {
            utilityStore.closeSideBarModal()
          }}
        />
      </WrapperFlexBetween>
    </>
  ])

  return (
    <BaseSideBarComponent
      headerTitle="Check Address"
      headerIcon={<EyeOpenIcon fill="var(--color-main)" />}
    >
      {currentStepElement}
    </BaseSideBarComponent>
  )
})
