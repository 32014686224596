import { observer } from 'mobx-react'
import { WrapperFlexBetween } from 'src/ui/Wrappers/WrapperFlexBetween'
import Button, { EButtonType } from 'src/ui/Button'
import {
  type BalanceBorder as TBalanceBorder,
  type BalanceBorders,
  type BalanceBorderOptions
} from 'src/models/NotificationsStore/NotificationsStore.types'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { useStore } from 'src/models/Store'
import { useParams } from 'react-router-dom'
import BalanceBorder from '../../Components/BalanceBorder/BalanceBorder'

type NotificationsDeleteBordersProps = {
  onCancel: () => void
  onDelete: (border: BalanceBorders) => void
  border: {
    toDelete: BalanceBorderOptions
    currency: string
    border: TBalanceBorder
  } | null
}

const NotificationsDeleteBorders = observer(
  ({ onCancel, onDelete, border }: NotificationsDeleteBordersProps) => {
    const {
      handleSubmit,
      formState: { isSubmitting }
    } = useForm()
    const { id } = useParams()
    const { notificationsStore } = useStore()

    if (border == null || id == null) {
      return null
    }
    const { border: borderObject, currency, toDelete } = border

    const onSubmit: SubmitHandler<BalanceBorders> = async () => {
      const response = await notificationsStore.deleteBalanceBorder({
        notificationId: parseInt(id),
        borderToDelete: toDelete,
        currency
      })
      if (response !== undefined) {
        onDelete({})
      }
    }
    return (
      <div>
        <h3>Delete this border?</h3>
        <form>
          <BalanceBorder currency={currency} borders={borderObject} />
          <WrapperFlexBetween marginBlock={40}>
            <Button
              wide
              title="Delete border"
              onClick={handleSubmit(onSubmit)}
              isLoading={isSubmitting}
            />
            <Button
              wide
              type="button"
              title="Cancel"
              onClick={onCancel}
              buttonType={EButtonType.SECONDARY}
            />
          </WrapperFlexBetween>
        </form>
      </div>
    )
  }
)
export default NotificationsDeleteBorders
