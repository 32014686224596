import type React from 'react'
import './WithdrawPage.scss'
import { useParams } from 'react-router-dom'
import BaseSideBarComponent from 'src/components/BaseSideBarComponent/BaseSideBarComponent'
import { colors } from 'src/assets/scss/themes'
import WithdrawIcon from 'src/assets/images/svg/WithdrawIcon'
import { useState } from 'react'
import WithdrawSelectWallet from './WithdrawSteps/WithdrawSelectWallet'
import WithdrawEnterAmount from './WithdrawSteps/WithdrawEnterAmount'
import WithdrawCompleted from './WithdrawSteps/WithdrawCompleted'
import {
  type SubmitHandler,
  useForm,
  type UseFormSetValue,
  type UseFormRegister
} from 'react-hook-form'
import { type SingleValue } from 'react-select'
import { observer } from 'mobx-react'
import { useStore } from 'src/models/Store'
import Preloader from 'src/ui/Preloader'
import { type TWithdrawAddress } from 'src/models/WithdrawAddressesStore'

/**
 * typings for react-router's useParams hook, specifically for withdraw pages
 */
export type UseParamsWithdraw = {
  balanceId: string
}
export type UseParamsWithdrawManage = {
  networkId: string
  coinName: string
  addressId: string
}

export type WithdrawFormInputs = {
  network: SingleValue<{ label: string; value: number }>
  amount: string
  selectedWallet: TWithdrawAddress
}

export type formStateType = React.Dispatch<
  React.SetStateAction<'selectWallet' | 'enterAmount' | 'completed'>
>

const WithdrawPage: React.FC = observer(() => {
  const { balanceId } = useParams<UseParamsWithdraw>() as UseParamsWithdraw
  const { initialStore, balancesStore, feesStore } = useStore()
  const [formStep, setFormStep] = useState<
    'selectWallet' | 'enterAmount' | 'completed'
  >('selectWallet')

  const {
    control,
    handleSubmit,
    register,
    formState: {
      errors: { amount },
      isSubmitting
    },
    setValue,
    getValues
  } = useForm<WithdrawFormInputs>({
    defaultValues: { amount: '0' }
  })

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const balanceItem = balancesStore.items.find(
    (item) => item.id === parseInt(balanceId)
  )

  if (balanceItem === undefined) {
    return <Preloader />
  }

  const fees = feesStore.b2b[balanceItem.currency]?.settlement_withdraw ?? []

  const onSubmitWithdraw: SubmitHandler<WithdrawFormInputs> = async ({
    amount,
    network
  }) => {
    const response = await balancesStore.withdraw({
      amount: parseFloat(amount.replace(',', '.')),
      // @ts-expect-error for now
      network_id: network?.value,
      currency: balanceItem.currency,
      destination: getValues('selectedWallet.id')
    })

    if (response !== undefined) {
      void balancesStore.getBalances({})
      setFormStep('completed')
    }
  }

  const renderFormStep = (): JSX.Element => {
    if (formStep === 'selectWallet') {
      return (
        <WithdrawSelectWallet
          setFormStep={setFormStep}
          formControl={control}
          setCurrentWallet={setValue}
          balanceItem={balanceItem}
        />
      )
    }

    if (formStep === 'enterAmount') {
      return (
        <WithdrawEnterAmount
          onChangeAddress={() => {
            setFormStep('selectWallet')
          }}
          hookFormRegister={
            register as unknown as UseFormRegister<{ amount: string }>
          }
          inputErrors={amount}
          onSubmit={handleSubmit(onSubmitWithdraw)}
          selectedWalletName={getValues('selectedWallet.name')}
          selectedWalletDetails={getValues('selectedWallet.slug')}
          isFormSubmitting={isSubmitting}
          setValue={setValue as unknown as UseFormSetValue<{ amount: string }>}
          depositLimitMin={0}
          depositLimitMax={0}
          availableBalance={balanceItem?.balance}
          feePercent={fees[0]?.percent_value ?? 0}
          feeAbsoluteAmount={fees[0]?.absolute_amount ?? 0}
          selectedCoin={balanceItem.currency}
        />
      )
    }

    if (formStep === 'completed') {
      return (
        <WithdrawCompleted
          amount={getValues('amount')}
          name={getValues('selectedWallet.name')}
          slug={getValues('selectedWallet.slug')}
        />
      )
    }
    return <div>Something went wrong, please contact support</div>
  }

  return (
    <BaseSideBarComponent
      headerIcon={<WithdrawIcon stroke={colors.main} />}
      headerTitle={`${balanceItem?.currency ?? ''} Withdraw`}
    >
      <div className="WithdrawPage">
        {initialStore.isLoading ? <Preloader /> : renderFormStep()}
      </div>
    </BaseSideBarComponent>
  )
})
export default WithdrawPage
