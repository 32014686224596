import type React from 'react'
import './BaseSideBarComponent.scss'
import { type ReactNode, type ReactElement } from 'react'
import { useStore } from 'src/models/Store'
import { observer } from 'mobx-react'
import EnableTFASidebarComponent from '../EnableTFASidebarComponent/EnableTFASidebarComponent'
import { CloseIcon } from 'src/assets/svg'

interface BaseSideBarComponentProps {
  headerIcon?: ReactElement<SVGElement> | null
  headerTitle?: string | undefined
  children: ReactNode
  /**
   * Checks if TFA is enabled, and if not show user Enable TFA Component
   */
  checkTFAEnabled?: boolean
}
/**
 * Component mostly used for styling and consistency purposes, when building a modal use this component
 * to have a base with header, icon etc.
 *
 * Also has a checkTFAEnabled prop, which checks for enabled TFA and prompts user to enable TFA before any further action
 */
const BaseSideBarComponent: React.FC<BaseSideBarComponentProps> = observer(
  ({ headerTitle, headerIcon, children, checkTFAEnabled }) => {
    const { utilityStore } = useStore()
    const {
      userStore: { isTFAEnabled }
    } = useStore()

    return (
      <div className="BaseSideBarComponent">
        {headerTitle !== undefined && (
          <div className="BaseSideBarComponent-Header">
            {headerIcon}
            {headerTitle}
            <CloseIcon
              className="CloseIcon"
              onClick={() => {
                utilityStore.closeSideBarModal()
              }}
            />
          </div>
        )}
        {checkTFAEnabled === true && !isTFAEnabled ? (
          <EnableTFASidebarComponent />
        ) : (
          children
        )}
      </div>
    )
  }
)
export default BaseSideBarComponent
