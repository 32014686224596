import { makeAutoObservable, runInAction } from 'mobx'
import { type BasePaginationRequest } from './FetchModel'
import { type Pagination } from './Store.types'

class FiltersStore<
  PossibleFilters extends Record<string, unknown> & Partial<Pagination>
> {
  constructor(initialFilters?: Partial<PossibleFilters>) {
    makeAutoObservable<FiltersStore<PossibleFilters>>(this)

    this.setInitialFilters({
      ...initialFilters,
      ...({ page: 1, page_size: 10 } as Partial<PossibleFilters>)
    })
  }

  activeFilters: PossibleFilters = {} as PossibleFilters
  pagination = {
    total: 0
  }

  private setInitialFilters(initialFilters: Partial<PossibleFilters>) {
    this.activeFilters = initialFilters as PossibleFilters
  }

  setFilter(field: keyof PossibleFilters, value: unknown) {
    runInAction(() => {
      if (value === null || value === '') {
        this.activeFilters[field] = undefined as any
      } else {
        this.activeFilters[field] =
          value as PossibleFilters[keyof PossibleFilters]
      }

      if (field !== 'page') {
        this.activeFilters.page = 1
      }
    })
  }

  getActiveFilterState(field: keyof PossibleFilters) {
    return this.activeFilters[field]
  }

  getIsFilterActive(field: keyof PossibleFilters) {
    return this.activeFilters[field] !== undefined
  }

  resetFilters() {
    this.activeFilters = { page: 1, page_size: 10 } as PossibleFilters
  }

  public get activeFilterCount(): number {
    return Object.keys(this.activeFilters).length
  }

  paginationResetFilters(total: number) {
    this.setFilter('page_size', 10)
    this.setFilter('total', total)
    this.setFilter('page', 1)
  }

  get paginationPageLength() {
    return Math.ceil(
      (this.pagination.total ?? 1) / (this.activeFilters.page_size ?? 1)
    )
  }

  /**
   * Used to set pagination after a get request
   */
  paginationSet({
    page_size,
    total
  }: Pick<BasePaginationRequest, 'page_size' | 'total'>) {
    this.activeFilters.page_size = page_size
    this.pagination.total = total
  }
}

export type TFieldValues = {
  field: string
  value: string | boolean
}

export default FiltersStore
