import { observer } from 'mobx-react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { SettingsIcon } from 'src/assets/svg'
import BaseSideBarComponent from 'src/components/BaseSideBarComponent/BaseSideBarComponent'
import Select from 'src/components/Select/Select'
import { useStore } from 'src/models/Store'
import Button, { EButtonType } from 'src/ui/Button'
import Input from 'src/ui/Input/Input'
import TextComponent from 'src/ui/TextComponent/TextComponent'
import { WrapperColumn } from 'src/ui/Wrappers/WrapperColumn'
import { WrapperFlexBetween } from 'src/ui/Wrappers/WrapperFlexBetween'

type FormInputs = {
  amount: string
  currency: { label: string; value: string }
  type: { label: string; value: 'greater_than' | 'lower_than' }
}

const NotificationsAddBorder = observer(() => {
  const { id } = useParams()
  const { initialStore, notificationsStore } = useStore()
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting }
  } = useForm<FormInputs>()

  const borderTypeOptions: Array<{
    label: string
    value: 'greater_than' | 'lower_than'
  }> = [
    { label: 'Greater than', value: 'greater_than' },
    { label: 'Lower than', value: 'lower_than' }
  ]

  const currencyOptions = initialStore.currency.map((currency) => {
    return { label: currency.name, value: currency.name }
  })

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    if (data.currency === undefined || data.type === undefined) {
      return
    }

    if (id === undefined) {
      return
    }

    const response = await notificationsStore.addBalanceBorder({
      borderCurrency: data.currency.value,
      borderToChange: { [data.type.value]: data.amount },
      notificationId: parseInt(id)
    })
    if (response !== undefined) {
      navigate('..')
    }
  }

  return (
    <BaseSideBarComponent
      headerIcon={<SettingsIcon />}
      headerTitle={'Add new border'}
    >
      <form style={{ paddingInline: 40 }}>
        <WrapperColumn gap={20}>
          <WrapperColumn>
            <TextComponent>Currency</TextComponent>
            <Select
              transparent
              options={currencyOptions}
              onChange={(e) => {
                if (e !== null) {
                  setValue('currency', e)
                }
              }}
            />
          </WrapperColumn>
          <WrapperColumn>
            <TextComponent>Type</TextComponent>
            <Select
              transparent
              options={borderTypeOptions}
              onChange={(e) => {
                if (e !== null) {
                  setValue('type', e)
                }
              }}
            />
          </WrapperColumn>
          <Input
            label="Amount"
            wide
            {...register('amount', {
              required: { value: true, message: 'This field is required' },
              pattern: {
                value: /^[+-]?([0-9]*[.])?[0-9]+$/,
                message: 'Invalid number'
              }
            })}
            errors={errors.amount}
          />
          <WrapperFlexBetween marginBlock={20}>
            <Button
              title="Add border"
              wide
              onClick={handleSubmit(onSubmit)}
              isLoading={isSubmitting}
            />
            <Button
              title="Cancel"
              wide
              buttonType={EButtonType.SECONDARY}
              onClick={() => {
                navigate('..')
              }}
            />
          </WrapperFlexBetween>
        </WrapperColumn>
      </form>
    </BaseSideBarComponent>
  )
})
export default NotificationsAddBorder
