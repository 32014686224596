import { type PropsWithChildren } from 'react'
import styles from './Wrappers.module.scss'

type WrapperFlexProps = {
  gap?: number
  marginBlock?: number
} & PropsWithChildren

export const WrapperFlex = ({
  gap = 10,
  marginBlock,
  children
}: WrapperFlexProps) => {
  return (
    <div className={styles.WrapperFlex} style={{ gap, marginBlock }}>
      {children}
    </div>
  )
}
