import type Store from './Store'
import { autorun, makeAutoObservable, runInAction } from 'mobx'
import FiltersStore from './FiltersStore'
import PaginationStore from './PaginationStore'
import { type BasePaginationRequest } from './FetchModel'

type TransactionStatus = 'pending' | 'success' | 'failed'

export type TransactionPageFilters = {
  page: number
  page_size: number
  total: number
  transaction_id?: string
  network?: string
  currency?: string
  date_start?: string
  date_end?: string
  status?: TransactionStatus
  risk_decision?: string
  transaction_type?: TransactionTypes
  foreign_id?: string
  magic_search?: string
}

export type TTransactionListItem = {
  id: number
  created: string
  amount: string
  network_fee: string | null
  type: string
  status: string
  real_amount: string
  amount_with_commission: string
  txid: string
  txid_url: string
  network: string
  coin: string
  merchant: string
  wallet: string
  foreign_id: string
  commission_amount: number
}

export type TransactionExchangeCalculation = {
  from_coin_name: string
  from_currency: string
  to_currency: string
  request_currency: string
  to_exchange_currency: string
  original_amount: number
  exchanged_amount: number
  exchange_rate: number
}

export type TransactionTypes =
  | 'customer_deposit'
  | 'customer_deposit_with_exchange'
  | 'top_up_deposit'
  | 'customer_withdraw'
  | 'customer_withdraw_with_exchange'
  | 'exchange_withdraw'
  | 'settlement_withdraw'

class TransactionStore {
  constructor(private readonly rootStore: Store) {
    this.rootStore = rootStore
    makeAutoObservable<TransactionStore, 'rootStore'>(this, {
      rootStore: false
    })

    this.pagination = new PaginationStore()
    this.filters = new FiltersStore<TransactionPageFilters>()
  }

  filters
  pagination
  list: TTransactionListItem[] = []
  currentTransaction: {
    transaction: TTransactionListItem
    exchange_calculation: TransactionExchangeCalculation | null
  } = {
    transaction: {} as TTransactionListItem,
    exchange_calculation: null
  }

  currentTransactionError: boolean = false
  currentTransactionLoading: boolean = false

  isLoading: boolean = false
  isFetched: boolean = false
  loadingError: boolean = false
  listener = (): void => {}

  subscribe(): void {
    this.listener = autorun(() => {
      if (this.rootStore.merchantStore.currentMerchant != null) {
        void this.getTransactions({
          ...this.filters.activeFilters
        })
      }
    })
  }

  unsubscribe(): void {
    this.listener()
    this.filters.resetFilters()
  }

  async getTransactionInfo(id: number): Promise<void> {
    this.currentTransactionLoading = true
    const response = await this.rootStore.api.get<{
      transaction: TTransactionListItem
      // logs: [unknown]
      exchange_calculation: TransactionExchangeCalculation
    }>(`/jsapi/transactions/${id}`)

    if (response !== undefined) {
      runInAction(() => {
        this.currentTransactionError = false
        this.currentTransaction = response
        this.currentTransactionLoading = false
      })
    }
  }

  async getTransactions(params: TransactionPageFilters): Promise<void> {
    this.isLoading = true
    const response = await this.rootStore.api.get<
      {
        items: TTransactionListItem[]
      } & BasePaginationRequest
    >('/jsapi/transactions', params)

    if (response !== undefined) {
      runInAction(() => {
        const { page_size, total } = response
        this.list = response.items
        this.filters.paginationSet({ page_size, total })
        this.isFetched = true
        this.isLoading = false
        this.loadingError = false
      })
    }
  }

  downloadTransactionPDF(id: TTransactionListItem['id']): void {
    window.open(`/jsapi/transactions/${id}/receipt`)
  }
}

export default TransactionStore
