import { observer } from 'mobx-react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { SettingsIcon } from 'src/assets/svg'
import BaseSideBarComponent from 'src/components/BaseSideBarComponent/BaseSideBarComponent'
import { type NotificationChannels } from 'src/models/NotificationsStore/NotificationsStore.types'
import { useStore } from 'src/models/Store'
import Button, { EButtonType } from 'src/ui/Button'
import Input from 'src/ui/Input/Input'
import ComponentLoading from 'src/ui/Loaders/ComponentLoading/ComponentLoading'
import { WrapperColumn } from 'src/ui/Wrappers/WrapperColumn'

const NotificationsManageChannels = observer(() => {
  const { notificationsStore, utilityStore } = useStore()
  const { notificationChannels } = notificationsStore
  const {
    register,
    formState: { isSubmitting },
    handleSubmit
  } = useForm({ defaultValues: notificationChannels })

  if (Object.keys(notificationsStore.notificationChannels).length < 1) {
    return <ComponentLoading />
  }

  const onSubmit: SubmitHandler<NotificationChannels> = async (data) => {
    const filteredData = Object.fromEntries(
      Object.entries(data).filter(([_, value]) => value.destination)
    )
    await notificationsStore.updateNotificationChannels(
      // @ts-expect-error all good for now
      filteredData
    )
  }

  return (
    <BaseSideBarComponent
      headerIcon={<SettingsIcon />}
      headerTitle="Alert delivery channels"
    >
      <form>
        <WrapperColumn gap={20}>
          {Object.entries(notificationChannels)
            .filter(([channel, data]) => data.display)
            .map(([channelId, { destination, name, display, editable }]) => (
              <div key={name}>
                <Input
                  wide
                  disabled={!editable}
                  label={name}
                  placeholder={destination ?? 'Not set'}
                  description={
                    name === 'Email'
                      ? '(You can’t change the email address)'
                      : ''
                  }
                  {...register(
                    `${channelId as keyof NotificationChannels}.destination`
                  )}
                />
              </div>
            ))}
        </WrapperColumn>
        <WrapperColumn marginBlock={40} gap={20}>
          <Button
            wide
            title="Save"
            type="submit"
            onClick={handleSubmit(onSubmit)}
            isLoading={isSubmitting}
          />
          <Button
            wide
            type="button"
            title="Cancel"
            buttonType={EButtonType.SECONDARY}
            onClick={() => {
              utilityStore.closeSideBarModal()
            }}
          />
        </WrapperColumn>
      </form>
    </BaseSideBarComponent>
  )
})
export default NotificationsManageChannels
