import { type PropsWithChildren } from 'react'
import styles from './BasicDropdown.module.scss'
import classNames from 'classnames'

type BasicDropdownProps = {
  isOpen: boolean
} & PropsWithChildren

/**
 * Basic dropdown with no state control
 */
const BasicDropdown = ({ isOpen, children }: BasicDropdownProps) => {
  return (
    <div
      className={classNames(styles.BasicDropdown, {
        [styles.Visible]: isOpen
      })}
    >
      {children}
    </div>
  )
}
export default BasicDropdown
