import { type ReactElement, useState } from 'react'
import useSideBarModal from 'src/hooks/useSideBarModal'

/**
 *
 * This hook allows for multi-step form.
 *
 * Pass components or jsx as array to display steps, example - [FirstStepComponent/>,SecondStepComponent/>]
 *
 * 2FA code input component could be provided as the second property in order to show it without removing current form step content,
 * it is useful if the form step needs to stay and an aditional OTP input is required to be shown in order to complete the form
 * and send data to BE.
 * needs to be specifically called with showOTPStep()
 *
 * For an example please check current implementations in the code base
 */
const useFormStepper = (
  formSteps: ReactElement[],
  OTPCodeComponent?: JSX.Element
) => {
  const [currentStep, setCurrentStep] = useState(0)
  const { openModal } = useSideBarModal()

  const moveToNextStep = (): void => {
    if (currentStep < formSteps.length) {
      setCurrentStep((currentStep) => currentStep + 1)
    }
  }

  const moveToSpecificStep = (index: typeof currentStep): void => {
    if (index <= formSteps.length && index >= 0) {
      setCurrentStep(index)
    }
  }

  const moveStepBack = (): void => {
    if (currentStep > 0) {
      setCurrentStep((currentStep) => currentStep - 1)
    }
  }

  const showOTPStep = (): any => {
    openModal(OTPCodeComponent)
  }

  return {
    /**
     * Display current element in the formSteps array
     */
    currentStepElement: formSteps[currentStep],
    /**
     * Move to the next step of the form, swapping current element with the next one in the array
     */
    moveToNextStep,
    /**
     * Move to specific step of the form, swapping current element with the next one in the array
     * @param index - move to specific index
     */
    moveToSpecificStep,
    moveStepBack,
    showOTPStep,
    /**
     * Returns the index in the array as number, representing a form step that the user is currently in
     */
    currentStepIndex: currentStep
  }
}
export default useFormStepper
