import type React from 'react'
import Input from 'src/ui/Input/Input'
import Button, { EButtonType } from 'src/ui/Button'
import { useNavigate } from 'react-router-dom'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { useStore } from 'src/models/Store'
import TextComponent from 'src/ui/TextComponent/TextComponent'

type PasswordResetFormInputs = {
  email: string
}

const PasswordResetPage: React.FC = () => {
  const navigate = useNavigate()
  const { userStore } = useStore()

  const {
    register,
    handleSubmit,
    formState: { isSubmitting }
  } = useForm<PasswordResetFormInputs>()

  const onSubmit: SubmitHandler<PasswordResetFormInputs> = async ({
    email
  }) => {
    const response = await userStore.resetPasswordUsingEmail(email)
    if (response !== undefined) {
      navigate('success')
    }
  }
  return (
    <div className="PasswordResetPage">
      <form>
        <h3>Request password change</h3>
        <p>
          To reset your password, enter the email address that you used to set
          up your Citron account. We&apos;ll send you a link to help you get
          back into your account.
        </p>
        <Input
          autoFocus
          label="Email"
          {...register('email', { required: true })}
        />
        <Button
          title="Reset"
          onClick={handleSubmit(onSubmit)}
          disabled={isSubmitting}
        />
        <Button
          title="Back to login"
          type="button"
          buttonType={EButtonType.LINK}
          onClick={() => {
            navigate('/login')
          }}
        />
        <TextComponent body4>
          If you have any issues, contact{' '}
          <a href="mailto:support@citrn.io">support@citrn.io</a>
        </TextComponent>
      </form>
    </div>
  )
}
export default PasswordResetPage
