export const NOTIFICATION_SETTINGS_PERMISSION =
  'merchants_notifications_settings_view'

export type NotificationSetting = {
  id: number
  type: string
  name: string
  description: string
  enabled: boolean
  params: {
    channels: string[]
    borders: BalanceBorders | null
  }
}

export type BalanceBorders = Record<string, BalanceBorder>

export type BalanceBorder = {
  greater_than?: number | null
  lower_than?: number | null
}

export type BalanceBorderOptions = 'greater_than' | 'lower_than'

export type NotificationChannelSetting = {
  name: string
  destination: string | null
  display: boolean
  editable: boolean
  enabled: boolean
}

export type NotificationDeliveryChannels = 'email' | 'slack' | 'websockets'

export type NotificationChannels = Record<
  NotificationDeliveryChannels,
  NotificationChannelSetting
>

export type Notifications = {
  notifications: Notification[]
  total_count: number
  page: number
  page_size: number
}
export type Notification = {
  message: string
  is_new: boolean
  created_at: string
}
