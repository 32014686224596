import { autorun, makeAutoObservable, reaction, runInAction, toJS } from 'mobx'
import type Store from '../Store'
import {
  type NotificationChannelSetting,
  type NotificationDeliveryChannels,
  type NotificationChannels,
  type NotificationSetting,
  type BalanceBorders,
  type BalanceBorder,
  type BalanceBorderOptions,
  NOTIFICATION_SETTINGS_PERMISSION,
  type Notifications
} from './NotificationsStore.types'
import { EAlertTypes } from '../AlertsStore'

class NotificationsStore {
  constructor(private readonly rootStore: Store) {
    this.rootStore = rootStore
    makeAutoObservable<NotificationsStore, 'rootStore'>(this, {
      rootStore: false
    })

    reaction(
      () => this.rootStore.websocketStore.isConnected,
      () => {
        if (this.rootStore.websocketStore.isConnected) {
          this.getNotificationHistory()
        }
      }
    )

    autorun(() => {
      if (this.rootStore.websocketStore.client == null) {
        return
      }
      this.rootStore.websocketStore.client.onmessage = ({ data }) => {
        const message = JSON.parse(data)
        if (message.command === 'notifications_history') {
          runInAction(() => {
            this.notifications = message.data
          })
        }
        if (message.command === 'notify') {
          this.getNotificationHistory()
          this.rootStore.alertsStore.addAlert({
            title: 'Notification',
            content: message.data.message,
            type: EAlertTypes.INFO,
            id: message.data.message,
            timeout: 10000
          })
        }
      }
    })
    reaction(
      () => this.onlyNewNotifications,
      () => {
        this.getNotificationHistory()
      }
    )
  }

  notificationSettings: NotificationSetting[] = []
  notificationChannels: NotificationChannels = {} as NotificationChannels
  balanceBorders: BalanceBorders = {} as BalanceBorders

  notifications: Notifications = {
    notifications: [],
    total_count: 0,
    page: 0,
    page_size: 0
  }

  onlyNewNotifications = false

  listener = () => {}

  subscribe(): void {
    if (
      !this.rootStore.initialStore.getIsPermissionEnabled(
        NOTIFICATION_SETTINGS_PERMISSION
      )
    ) {
      this.rootStore.alertsStore.alertPermissionDeniedPage(
        NOTIFICATION_SETTINGS_PERMISSION
      )
      return
    }
    this.listener = autorun(() => {
      void this.getNotificationsSettings()
      void this.getNotificationChannels()
    })
  }

  unsubscribe(): void {
    this.listener()
  }

  private getNotificationHistory() {
    if (this.rootStore.websocketStore.client == null) {
      return
    }
    this.rootStore.websocketStore.client.send(
      JSON.stringify({
        command: 'notifications_history',
        data: {
          page: 1,
          page_size: 100000,
          only_new: this.onlyNewNotifications
        }
      })
    )
  }

  clearNotifications() {
    if (this.rootStore.websocketStore.client == null) {
      return
    }
    this.rootStore.websocketStore.client.send(
      JSON.stringify({
        command: 'mark_notifications_as_read',
        data: {}
      })
    )
    this.getNotificationHistory()
  }

  private async getNotificationsSettings() {
    const response = await this.rootStore.api.get<NotificationSetting[]>(
      'jsapi/notifications-settings'
    )

    if (response !== undefined) {
      const balanceBorders = response.find(
        (setting) =>
          setting.type === 'max_min_balance' &&
          setting.params.borders !== undefined
      )?.params.borders

      runInAction(() => {
        this.balanceBorders = balanceBorders ?? {}
        this.notificationSettings = response
      })
    }
  }

  private async getNotificationChannels() {
    const response = await this.rootStore.api.get<{
      channels: NotificationChannels
    }>('/jsapi/notifications-channels')

    if (response !== undefined) {
      runInAction(() => (this.notificationChannels = response.channels))
    }
  }

  async updateNotificationChannels(
    data: Record<
      NotificationDeliveryChannels,
      Pick<NotificationChannelSetting, 'destination'>
    >
  ) {
    const response = await this.rootStore.api.update<{
      channels: NotificationChannels
    }>(
      '/jsapi/notifications-channels',
      { channels: data },
      { title: 'Success', content: 'Channels updated!' }
    )
    if (response !== undefined) {
      runInAction(() => {
        this.notificationChannels = response.channels
      })
    }
    return response
  }

  private findNotificationSettingById(id: number) {
    return this.notificationSettings.find((setting) => setting.id === id)
  }

  async addBalanceBorder({
    notificationId,
    borderCurrency,
    borderToChange
  }: {
    notificationId: number
    borderCurrency: string
    borderToChange: Partial<BalanceBorder>
  }) {
    const setting = toJS(this.findNotificationSettingById(notificationId))

    if (setting === undefined) {
      return undefined
    }

    let settingWithNewBorder: NotificationSetting = {} as NotificationSetting

    if (borderToChange.greater_than !== undefined) {
      settingWithNewBorder = {
        ...setting,
        params: {
          ...setting.params,
          borders: {
            ...setting.params.borders,
            [borderCurrency]: {
              greater_than: borderToChange.greater_than,
              lower_than:
                setting.params?.borders?.[borderCurrency]?.lower_than ?? null
            }
          }
        }
      }
    }

    if (borderToChange.lower_than !== undefined) {
      settingWithNewBorder = {
        ...setting,
        params: {
          ...setting.params,
          borders: {
            ...setting.params.borders,
            [borderCurrency]: {
              greater_than:
                setting.params?.borders?.[borderCurrency]?.greater_than ?? null,
              lower_than: borderToChange.lower_than
            }
          }
        }
      }
    }

    const response = await this.rootStore.api.update<NotificationSetting>(
      '/jsapi/notifications-settings/' + notificationId.toString(),
      settingWithNewBorder,
      { content: 'Border added!' }
    )

    if (response !== undefined) {
      await this.getNotificationsSettings()
      return response
    }
  }

  async updateBalanceBorder(notificationId: number, border: BalanceBorders) {
    const setting = toJS(this.findNotificationSettingById(notificationId))

    if (setting === undefined || setting.params.borders == null) {
      // Border needs to be created first
      return undefined
    }
    const currency = Object.keys(border)[0]

    const newBorders = {
      ...setting.params.borders,
      [currency]: Object.values(border)[0]
    }

    const settingWithNewBorders: NotificationSetting = {
      ...setting,
      params: {
        ...setting.params,
        borders: newBorders
      }
    }

    const response = await this.rootStore.api.update<NotificationSetting>(
      '/jsapi/notifications-settings/' + notificationId.toString(),
      settingWithNewBorders,
      { content: 'Border updated!' }
    )

    if (response !== undefined) {
      await this.getNotificationsSettings()
      return response
    }
  }

  async deleteBalanceBorder({
    notificationId,
    borderToDelete,
    currency
  }: {
    notificationId: number
    currency: string
    borderToDelete: BalanceBorderOptions
  }) {
    const setting = this.findNotificationSettingById(notificationId)
    if (setting === undefined || setting.params.borders == null) {
      this.rootStore.alertsStore.addAlert({
        content: 'Border needs to be created first',
        title: 'Error',
        type: EAlertTypes.ERROR,
        timeout: 3000,
        id: Date.now()
      })
      return undefined
    }

    const newBorders = {
      ...setting.params.borders,
      [currency]: {
        ...setting.params.borders[currency],
        [borderToDelete]: null
      }
    }
    if (Object.values(newBorders[currency]).every((value) => value === null)) {
      // @ts-expect-error all good
      newBorders[currency] = undefined
    }

    const settingWithNewBorders: NotificationSetting = {
      ...setting,
      params: {
        ...setting.params,
        borders: newBorders
      }
    }
    const response = await this.rootStore.api.update<NotificationSetting>(
      '/jsapi/notifications-settings/' + notificationId.toString(),
      settingWithNewBorders,
      { content: 'Border deleted!' }
    )

    if (response !== undefined) {
      await this.getNotificationsSettings()
      return response
    }
  }

  async updateNotificationSetting(newSetting: NotificationSetting) {
    const response = await this.rootStore.api.update<NotificationSetting>(
      '/jsapi/notifications-settings/' + newSetting.id.toString(),
      newSetting
    )

    if (response !== undefined) {
      await this.getNotificationsSettings()
      return response
    }
  }

  async editChannelEnabled(
    setting: NotificationSetting,
    channelName: string,
    enabled: boolean
  ) {
    let currentEnabledChannels = setting.params.channels

    if (enabled) {
      currentEnabledChannels.push(channelName)
    } else {
      currentEnabledChannels = currentEnabledChannels.filter(
        (channel) => channel !== channelName
      )
    }

    const response = await this.updateNotificationSetting({
      ...setting,
      params: { ...setting.params, channels: currentEnabledChannels }
    })

    if (response !== undefined) {
      return response
    }
  }
}

export default NotificationsStore
