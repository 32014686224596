import type React from 'react'
import './ExchangeModal.scss'
import { Form, InputGroup } from 'react-bootstrap'
import { useStore } from 'src/models/Store'
import { useForm } from 'react-hook-form'
import { type BalanceItem } from 'src/models/BalancesStore'
import { type OptionProps, components, type ControlProps } from 'react-select'
import Select from 'src/components/Select/Select'
import DoubleArrowIcon from 'src/assets/images/svg/DoubleArrowIcon'
import { useEffect, useState } from 'react'
import Button from 'src/ui/Button'
import { useTimer } from 'react-timer-hook'
import { observer } from 'mobx-react'
import useDebounce from 'src/hooks/useDebounce'
import Preloader from 'src/ui/Preloader'
import InfoIcon from 'src/assets/images/svg/InfoIcon'
import { colors } from 'src/assets/scss/themes'
import useFormStepper from 'src/utils/hooks/useFormStepper'
import ExchangeModalCompleteExchange from './ExchangeModalCompleteExchange'
import ExchangeModalQuota from './ExchangeModalQuota'
import { type ExchangeQuota } from 'src/models/ExchangeStore'
import { EAlertTypes } from 'src/models/AlertsStore'
import { CurrencyIcon } from 'src/ui/CurrencyIcon/CurrencyIcon'
interface ExchangeModalProps {
  chosenCoin: BalanceItem & ReactSelectDefaults
  networkId?: number
}

interface IFormInput {
  exchangeAmountFrom: string
  exchangeAmountTo: string
}

interface ReactSelectDefaults {
  label: string
  value: BalanceItem
}

export type OptionsWithReactSelectDefaults = BalanceItem & ReactSelectDefaults

const ExchangeModal: React.FC<ExchangeModalProps> = observer(
  ({ chosenCoin, networkId }) => {
    const [exchangeReversed, setExchangeReversed] = useState(false)
    const { balancesStore, exchangeStore, alertsStore } = useStore()
    const { getCurrencyNetworkId } = balancesStore
    const [previewQuota, setPreviewQuota] = useState<ExchangeQuota | null>(null)
    const [exchange, setExchange] = useState<{
      exchangeFrom: OptionsWithReactSelectDefaults | null
      exchangeTo: OptionsWithReactSelectDefaults | null
    }>({
      exchangeFrom: chosenCoin,
      exchangeTo: null
    })

    const {
      register,
      handleSubmit,
      formState,
      resetField,
      getValues,
      setValue,
      trigger
    } = useForm<IFormInput>({
      defaultValues: {
        exchangeAmountFrom: undefined,
        exchangeAmountTo: undefined
      },
      mode: 'all',
      criteriaMode: 'all'
    })
    if (networkId === undefined) {
      return (
        <div className="ExchangeModal" key={1}>
          <h2>Exchange not available</h2>
        </div>
      )
    }

    const { isValid, errors } = formState
    const minimumExchangeAmount = 0.0001
    const amountPattern = /^(0|[1-9]\d*)((\.|,)\d+)?$/

    // React Select needs a 'label' and 'value' to work properly plus filter out coin that is being exchanged from
    const selectOptions: OptionsWithReactSelectDefaults[] = balancesStore.items
      .filter(
        (item) =>
          item.currency !== exchange.exchangeFrom?.currency &&
          item.currency !== exchange.exchangeTo?.currency
      )
      .map((item) => {
        return { ...item, label: item.currency, value: item }
      })

    const createPreviewQuota = useDebounce(
      async (
        request_coin,
        lastChangedFormValue: 'exchangeAmountFrom' | 'exchangeAmountTo'
      ): Promise<any> => {
        if (!isValid || errors.exchangeAmountFrom?.message !== undefined) {
          return
        }

        const currentFormValues = getValues(lastChangedFormValue)
        const request_amount = currentFormValues
        const sanitizedRequestAmount = request_amount.replace(',', '.')
        if (exchange.exchangeFrom === null || exchange.exchangeTo === null) {
          alertsStore.addAlert({
            content: 'Something went wrong',
            type: EAlertTypes.WARNING,
            id: 'exchange-error',
            timeout: 3000,
            title: 'Error'
          })
          return
        }
        const receivedQuota = await exchangeStore.createQuota({
          from_currency: exchange.exchangeFrom?.currency,
          to_currency: exchange.exchangeTo?.currency,
          request_amount: sanitizedRequestAmount,
          request_currency: exchange.exchangeFrom.currency
        })

        if (receivedQuota !== undefined) {
          setPreviewQuota(receivedQuota)
          setValue('exchangeAmountTo', receivedQuota.to_amount)
        }
      },
      500
    )

    const { totalSeconds, restart } = useTimer({
      expiryTimestamp: new Date(),
      autoStart: false,
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onExpire: () => {
        createPreviewQuota(
          exchange.exchangeFrom?.currency,
          'exchangeAmountFrom'
        )
      }
    })

    useEffect(() => {
      if (previewQuota?.expire_time === undefined) {
        return
      }
      const today = new Date()
      today.setSeconds(today.getSeconds() + previewQuota.expire_time)
      restart(today)
    }, [previewQuota?.expire_timestamp])

    useEffect(() => {
      void updateFromCoinBalanceLimit()
    }, [exchange?.exchangeFrom?.currency])

    const updateFromCoinBalanceLimit = async (): Promise<void> => {
      if (exchange.exchangeFrom !== null) {
        const response = await exchangeStore.getNetworkExchangeLimits({
          currency: exchange.exchangeFrom.currency,
          network_id: getCurrencyNetworkId(exchange.exchangeFrom.id)
        })

        if (response === undefined) {
          setExchange({
            ...exchange,
            exchangeFrom: null
          })
        } else {
          setExchange({
            ...exchange,
            exchangeFrom: { ...exchange.exchangeFrom, balance: response.limit }
          })
        }
      }
    }

    const handleReverseExchange = (): void => {
      if (exchange.exchangeTo === null) {
        return
      }
      resetField('exchangeAmountFrom')
      setExchangeReversed(!exchangeReversed)
      setExchange({
        exchangeFrom: exchange.exchangeTo,
        exchangeTo: exchange.exchangeFrom
      })
      setPreviewQuota(null)
    }

    const handlePercentAmountSelect = async (
      percent: number
    ): Promise<void> => {
      await trigger('exchangeAmountFrom')
      if (exchange.exchangeFrom?.balance === undefined) return

      const calculatedPercentFromAmount =
        (parseFloat(exchange.exchangeFrom?.balance) / 100) * percent
      setValue('exchangeAmountFrom', calculatedPercentFromAmount.toString())
      createPreviewQuota(exchange.exchangeFrom.currency, 'exchangeAmountFrom')
    }

    const handleCompleteExchange = async (): Promise<
      { success: boolean } | undefined
    > => {
      if (previewQuota?.quote_id === undefined) {
        return undefined
      }

      const response = await exchangeStore.createExchange(previewQuota.quote_id)
      if (response !== undefined) {
        moveToNextStep()
      }
    }

    // React select custom component
    const Option = (
      props: OptionProps<OptionsWithReactSelectDefaults, false>
    ): JSX.Element => {
      return (
        <components.Option className="ExchangeModal-SelectOption" {...props}>
          <CurrencyIcon currency={props.data.currency} />
          {props.children}
        </components.Option>
      )
    }

    // React select custom component
    const Control = (
      props: ControlProps<OptionsWithReactSelectDefaults, false>
    ): JSX.Element => {
      const selectedValue = props.getValue()
      return (
        <components.Control {...props}>
          {selectedValue.length > 0 && (
            <CurrencyIcon currency={selectedValue[0].currency} />
          )}
          {props.children}
        </components.Control>
      )
    }
    const { currentStepElement, moveToNextStep } = useFormStepper([
      <div className="ExchangeModal" key={1}>
        <h2>Select amount for exchange</h2>
        Available amount: {exchange.exchangeFrom?.balance}{' '}
        {exchange.exchangeFrom?.currency}
        <div className="ExchangeModal-InputError">
          {exchange.exchangeTo === null && 'Please select coins first'}
        </div>
        <div className="ExchangeModal-Input">
          <Select
            small
            components={{ Option, Control }}
            options={selectOptions}
            value={exchange.exchangeFrom}
            onChange={(e) => {
              if (e !== null) {
                setExchange({ ...exchange, exchangeFrom: e })
              }
            }}
          />
          <InputGroup>
            <div className="ExchangeModal-InputSign">-</div>
            <Form.Control
              placeholder="0.00"
              className="Input"
              disabled={
                exchange.exchangeFrom === null || exchange.exchangeTo === null
              }
              {...register('exchangeAmountFrom', {
                max: {
                  value: exchange.exchangeFrom?.balance ?? 'Not available',
                  message:
                    'Amount to exchange cannot exceed the available amount'
                },
                min: {
                  value: minimumExchangeAmount,
                  message: `Amount to exchange cannot be less than the minimum - ${minimumExchangeAmount}`
                },
                pattern: {
                  value: amountPattern,
                  message: 'Please enter a valid amount for exchange'
                },
                minLength: 1,
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  createPreviewQuota(
                    exchange.exchangeFrom?.currency,
                    e.target.name
                  )
                }
              })}
            />
          </InputGroup>
        </div>
        <div className="ExchangeModal-PercentButtons">
          <Button
            title="25%"
            disabled={
              exchange.exchangeFrom === null || exchange.exchangeTo === null
            }
            onClick={() => {
              void handlePercentAmountSelect(25)
            }}
          />
          <Button
            title="50%"
            disabled={
              exchange.exchangeFrom === null || exchange.exchangeTo === null
            }
            onClick={() => {
              void handlePercentAmountSelect(50)
            }}
          />
          <Button
            title="75%"
            disabled={
              exchange.exchangeFrom === null || exchange.exchangeTo === null
            }
            onClick={() => {
              void handlePercentAmountSelect(75)
            }}
          />
          <Button
            title="100%"
            disabled={
              exchange.exchangeFrom === null || exchange.exchangeTo === null
            }
            onClick={() => {
              void handlePercentAmountSelect(100)
            }}
          />
        </div>
        <DoubleArrowIcon
          className={`ExchangeModal-ExchangeIcon ${
            exchangeReversed ? 'reversed' : 'default'
          }`}
          onClick={handleReverseExchange}
        />
        <div className="ExchangeModal-Input">
          <Select
            small
            components={{ Option, Control }}
            options={selectOptions}
            value={exchange.exchangeTo}
            onChange={(e) => {
              if (e !== null) {
                setExchange({ ...exchange, exchangeTo: e })
              }
            }}
          />
          <InputGroup>
            <div className="ExchangeModal-InputSign">+</div>
            <Form.Control
              placeholder="0.00"
              className="Input"
              disabled={
                exchange.exchangeFrom === null || exchange.exchangeTo === null
              }
              {...register('exchangeAmountTo', {
                pattern: {
                  value: amountPattern,
                  message: 'Please enter a valid amount for exchange'
                },
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  createPreviewQuota(
                    exchange.exchangeTo?.currency,
                    e.target.name
                  )
                }
              })}
            />
          </InputGroup>
        </div>
        <div className="ExchangeModal-InputError">
          {errors.exchangeAmountFrom?.message}
        </div>
        <div className="ExchangeModal-Rates">
          {previewQuota !== null && !exchangeStore.isGeneratingQuota && (
            <ExchangeModalQuota quota={previewQuota} />
          )}
          {exchangeStore.isGeneratingQuota && (
            <div className="ExchangeModal-PreviewLoader">
              <Preloader />
            </div>
          )}
        </div>
        {exchange.exchangeFrom !== null && exchange.exchangeTo !== null && (
          <Button
            disabled={!isValid}
            title={`Exchange ${exchange.exchangeFrom.currency} to ${exchange.exchangeTo.currency} `}
            isLoading={formState.isSubmitting}
            onClick={handleSubmit(handleCompleteExchange)}
          />
        )}
        {previewQuota !== null && (
          <div className="ExchangeModal-Timer">
            <InfoIcon fill={colors.bg} fillbackground={colors.main} />
            <p>After {totalSeconds} seconds price will be changed!</p>
          </div>
        )}
      </div>,
      <ExchangeModalCompleteExchange
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
        exchangeFromCoin={exchange.exchangeFrom?.currency!}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
        exchangeToCoin={exchange.exchangeTo?.currency!}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        quota={previewQuota!}
        key={2}
      />
    ])

    return currentStepElement
  }
)
export default ExchangeModal
