import BaseSideBarComponent from 'src/components/BaseSideBarComponent/BaseSideBarComponent'
import { SettingsIcon } from 'src/assets/svg'
import { observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'
import { useStore } from 'src/models/Store'
import TextComponent from 'src/ui/TextComponent/TextComponent'
import { WrapperFlexBetween } from 'src/ui/Wrappers/WrapperFlexBetween'
import Button, { EButtonType } from 'src/ui/Button'
import BalanceBorder from './Components/BalanceBorder/BalanceBorder'
import { WrapperColumn } from 'src/ui/Wrappers/WrapperColumn'

const NotificationsBalanceBorders = observer(() => {
  const {
    notificationsStore: { balanceBorders }
  } = useStore()

  const navigate = useNavigate()

  const isBordersEmpty = Object.keys(balanceBorders).length < 1
  return (
    <BaseSideBarComponent
      headerTitle="Balance borders"
      headerIcon={<SettingsIcon />}
    >
      <WrapperColumn gap={20}>
        {isBordersEmpty ? (
          <TextComponent centered>No borders added.</TextComponent>
        ) : (
          Object.entries(balanceBorders).map(([currency, borders]) => (
            <BalanceBorder
              key={currency}
              currency={currency}
              borders={borders}
            />
          ))
        )}
      </WrapperColumn>
      <WrapperFlexBetween marginBlock={40}>
        <Button
          title="Add new border"
          wide
          onClick={() => {
            navigate('add-border')
          }}
        />
        <Button
          title="Manage borders"
          wide
          buttonType={EButtonType.SECONDARY}
          onClick={() => {
            navigate('manage')
          }}
          disabled={isBordersEmpty}
        />
      </WrapperFlexBetween>
    </BaseSideBarComponent>
  )
})
export default NotificationsBalanceBorders
