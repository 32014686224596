import styles from './Switch.module.scss'
// eslint-disable-next-line import/no-named-default
import { default as ReactSwitch, type ReactSwitchProps } from 'react-switch'
import classNames from 'classnames'

type SwitchProps = {
  prop?: string
} & ReactSwitchProps

export const Switch = ({ ...props }: SwitchProps) => {
  return (
    <ReactSwitch
      className={classNames(styles.Switch, {
        [styles.On]: props.checked,
        [styles.Off]: !props.checked
      })}
      handleDiameter={19}
      offColor="#e0e0e0"
      onColor="#000"
      offHandleColor="#474747"
      onHandleColor="#F3FF00"
      checkedIcon={false}
      uncheckedIcon={false}
      width={41}
      height={25}
      borderRadius={11}
      {...props}
    />
  )
}
